/* eslint-disable import/no-anonymous-default-export */
import Api from "./Api";
import AuthToken from "./AuthTokenServices";

import { toast } from "react-toast";
export default {

  async getNullProdPosition(payload) {
    const response = await Api().post(`api/productOpen/get-null-position`);
    return response;
  },

  async geMaxProdPosition(payload) {
    const response = await Api().post(`api/productOpen/get-max-position`);
    return response;
  },

  async getUserProfile(payload) {
    await AuthToken.getCurrentAuth();
    const response = await Api().get(`api/dashboard/user/${payload}`);
    return response;
  },


  async updateUserInfo(payload) {
    await AuthToken.getCurrentAuth();
    const response = await Api().post(
      `api/dashboard/user/update-user-info`,
      payload
    );
    return response;
  },

  async saveMUser(payload) {
    await AuthToken.getCurrentAuth();
    try {
      let {
        user_id,
        user_email: email,
        user_first_name: firstname,
        user_last_name: lastname,
        user_mobile: mobile,
        user_password: password,
        user_role: user_role_id,
        user_company_name: user_company_name,
        user_profile_image: user_profile_image,
        user_is_hr_profesional: user_is_hr_profesional,
        user_is_hr_source_provider: user_is_hr_source_provider,
      } = payload;
      let body = {
        user_id,
        email,
        firstname,
        lastname,
        mobile: mobile,
        password,
        user_role_id: +user_role_id,
      };

      const res = await Api().post("api/dashboard/user/", payload);

      if (res && res.data.status) {
        toast.success(res.data.message);
        return res;
      } else {
        return res;
      }
    } catch (error) {

      return null;
    }
  },

  async getAllMUsersList() {
    await AuthToken.getCurrentAuth();
    const response = await Api().get(`api/dashboard/user`);
    return response;
  },

  async getRoleDetails(role_id) {
    await AuthToken.getCurrentAuth();
    const res = await Api().get(`api/dashboard/masters/role/${role_id}`);
    return res;
  },
  async getAllRole(payload) {
    await AuthToken.getCurrentAuth();
    const res = await Api().get(
      `api/dashboard/masters/role/?search=${payload}`
    );
    return res;
  },

  async getAllClaim(payload) {
    await AuthToken.getCurrentAuth();
    const res = await Api().get(
      `api/dashboard/product/user/prod/claimed/users/all-claimed/?search=${payload?.prod_is_active}&&prod_is_approved=${payload?.prod_is_approved}&&prod_is_unclaimed=${payload?.prod_is_unclaimed}&&filter_id=${payload?.filter_id}`
    );
    return res;
  },


  async getAllReviews() {
    await AuthToken.getCurrentAuth();
    const res = await Api().get(
      `api/dashboard/review/?search=1`
    );
    return res;
  },


  async saveClaim(payload) {
    await AuthToken.getCurrentAuth();
    const response = await Api().post(
      `api/dashboard/product/claimed-approve`, //old wala save th end point
      payload
    );
    return response;
  },
  async updateUserClaimed(payload) {
    await AuthToken.getCurrentAuth();
    const response = await Api().post(
      `api/dashboard/product/change-user/`,
      payload
    );
    return response;
  },



  // Qc Test Lots API Ends
  async getPermissionsByRoleID(payload) {
    await AuthToken.getCurrentAuth();
    const response = await Api().get(`api/dashboard/masters/role/${payload}`);
    return response;
  },

  async getAllPermissions() {
    await AuthToken.getCurrentAuth();
    const response = await Api().get(`api/dashboard/masters/permission/list`);
    return response;
  },

  async saveRole(payload) {
    await AuthToken.getCurrentAuth();
    const response = await Api().post(
      `api/dashboard/masters/role/auto-save`, //old wala save th end point
      payload
    );
    return response;
  },

  async getAllProduct(payload) {
    await AuthToken.getCurrentAuth();
    const response = await Api().get(
      `api/dashboard/product/?search=${payload?.prod_is_active}&&prod_is_sponsored=${payload?.prod_is_sponsored}&&prod_is_normal=${payload?.prod_is_normal}&&prod_is_all=${payload?.prod_is_all}&&page=${payload?.page}&&pageLimit=${payload?.pageLimit}&&prod_position_filter=${payload?.prod_position_filter}`
    );
    return response;
  },
  // async deleteProd(payload) {
  //   const response = await Api().post(
  //     `api/dashboard/product/deleteProd/`,
  //     payload
  //   );
  //   return response;
  // },
  async updateProductFirstPublish(payload) {
    await AuthToken.getCurrentAuth();
    try {

      const res = await Api().post("api/dashboard/product/first-publish/", payload);

      if (res && res.data.status) {
        toast.success(res.data.message);
        return res;
      } else {
        return res;
      }
    } catch (error) {

      return null;
    }
  },
  async removeProduct(payload) {
    const response = await fetch(`${process.env.REACT_APP_LIVE_SEARCHER_BACKEND_URL}api/products/${payload.id}?populate=*`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${process.env.REACT_APP_SEARCHER_API_TOKEN}`
      }
    }).then(v => v.json());
    console.log(response)
    return response;
  },
  async deleteProduct(payload) {
    const response = await fetch(`${process.env.REACT_APP_LIVE_SEARCHER_BACKEND_URL}api/products/${payload?.id}?populate=*`, {
      method: 'PUT',
      body: JSON.stringify(payload.body),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${process.env.REACT_APP_SEARCHER_API_TOKEN}`
      }
    }).then(v => v.json());

    return response;
  },

  async approveModalProduct(payload) {
    await AuthToken.getCurrentAuth();
    const response = await Api().post(
      `api/dashboard/product/claimed-approve`, //old wala save th end point
      payload
    );
    console.log("responsejj", response)

    if (response.data.status) {
      const responseto = await fetch(`${process.env.REACT_APP_LIVE_SEARCHER_BACKEND_URL}api/products/${payload?.id}?populate=*`, {
        method: 'PUT',
        body: JSON.stringify({ data: { is_approved: payload.is_approved } }),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${process.env.REACT_APP_SEARCHER_API_TOKEN}`
        }
      }).then(v => v.json());
    }


    return response;
  },
  async saveProductData(payload) {
    await AuthToken.getCurrentAuth();
    const response = await Api().post(`api/dashboard/product/`, payload);
    return response;
  },
  /* delete and active inactive both use */
  async removeMaster(payload) {
    await AuthToken.getCurrentAuth();
    const response = await Api().post(
      `api/dashboard/masters/master/remove/`,
      payload
    );
    return response;
  },

  /* Upload File Master use */
  async uploadMaster(payload) {
    await AuthToken.getCurrentAuth();
    const response = await Api().post(
      `api/dashboard/masters/master/upload/`,
      payload
    );
    return response;
  },

  /* Upload PDF File Master use */
  async uploadPDF(payload) {
    await AuthToken.getCurrentAuth();
    const response = await Api().post(
      `api/dashboard/masters/master/upload-pdf/`,
      payload
    );
    return response;
  },

  async uploadVideo(payload) {
    await AuthToken.getCurrentAuth();
    const response = await Api().post(
      `api/dashboard/masters/master/upload-video/`,
      payload
    );
    return response;
  },

  // check Product Position

  async changeProductPosition(payload) {
    await AuthToken.getCurrentAuth();
    const response = await Api().post( `api/dashboard/product/check-prod-position`,payload);
    return response;
  },

  async updateProductPosition(payload) {
    await AuthToken.getCurrentAuth();
    const response = await Api().post( `api/dashboard/product/update-prod-position`,payload);
    return response;
  },

  // getAllEnquries 

  async getAllEnquiry(payload) {
    await AuthToken.getCurrentAuth();
    const search = payload?.search || '';
    const response = await Api().get(
      `api/dashboard/enq?search=${search}`
    );
    return response;
  },

  // getAllFollowUp

  async getAllFollow(){
    await AuthToken.getCurrentAuth();
    const res = await Api().get(
      `api/dashboard/follow/?search=1`
    );
    return res;
  },

  async saveProductUser(payload) {
    await AuthToken.getCurrentAuth();
    const response = await Api().post(
      `api/dashboard/product/update-prodUser`, payload
    );
    return response;
  },
};


