import React, { useEffect, useState, Fragment } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import Table from "../../../components/tables/table";
import { Dialog, Transition } from "@headlessui/react";
import moment from "moment";
import { FallingLinesLoader } from "../../../components/spinners/Spinner";
import Breadcrumb from "../../../components/Breadcrumb";
import { exportToExcel } from "../../../helper/commonHelper";
import MasterServices from "../../../ApiServices/MasterServices";
import Select from "react-select";
import { followup_columns, followerUser_column } from "../../../components/tables/tableheader";

const FollowUpList = () => {
    const [isLoading, setIsLoading] = useState(true);
    const pages = [{ title: "FollowUp List", href: "/followup-list" }];
    const [followData, setFollowData] = useState([])
    const [filterData, setfilteredData] = useState([])
    const [productOption, setProductOption] = useState()
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [modalOpenFlage, setmodalOpenFlage] = useState({
        isOpen: false,
        data: []
    })
    const [filters, setFilters] = useState({
        searchProduct: null,
        startDate: null,
        endDate: null,
    });

    useEffect(() => {
        getAllFollowUpList();
    }, [])

    const getAllFollowUpList = async () => {
        setIsLoading(true);
        const response = await MasterServices.getAllFollow();
        console.log("getAllFollowUpList", response?.data?.data?.getProductBasedFollowing)
        if (response?.data?.data?.getProductBasedFollowing?.length > 0) {
            const finalData = response?.data?.data?.getProductBasedFollowing.filter((ele) =>
                ele?.m_following.some((item) => item?.follow_cu_relation?.user_is_active === true)
            );

            console.log("finalData", finalData);

            setFollowData(finalData)
            setfilteredData(finalData)
            setProductOption(finalData.map((v) => ({ label: v.ser_prod_name, value: v.ser_id, ...v })))
            setIsLoading(false)
        } else {
            setFollowData([])
            setIsLoading(false)
        }
    }

    const onModalOpen = (data) => {
        console.log("datadata", data);
        setmodalOpenFlage({
            isOpen: true,
            data: data
        });
    };


    useEffect(() => {
        if (followData?.length > 0) {
            let filterValue = followData;
            console.log("filterValue", filterValue)
            if (filters?.searchProduct?.length > 0) {
                filterValue = filters?.searchProduct

            }

            if (filters.startDate || filters.endDate) {
                const startDate = filters?.startDate
                    ? new Date(filters?.startDate)
                    : new Date("1000-01-01");

                const endDate = filters?.endDate ? new Date(filters?.endDate) : new Date();

                startDate?.setHours(0, 0, 0, 0);
                endDate?.setHours(23, 59, 59, 999);

                filterValue = filterValue.filter((item) => {
                    return item.m_following.some((ele) => {
                        const productDate = new Date(ele.follow_created_at);
                        return productDate >= startDate && productDate <= endDate;
                    })

                });
            }
            setfilteredData(filterValue)
        }

    }, [filters])

    const clearForm = () => {

        setfilteredData(null);

        setEndDate("");
        setStartDate("");
        setFilters({
            searchProduct: null,
            startDate: null,
            endDate: null,
        });
    };
    const downloadExcel = () => {
        const dataToExport = filterData || followData;

        let columns = [];
        let transformedData = [];


        // Columns for activeTab === 1
        columns = [
            // { Header: "UserName", accessor: "UserName" },
            // { Header: "Email", accessor: "Email" },
            // { Header: "Phone No", accessor: "Phone No" },
            // { Header: "Designation", accessor: "Designation" },
            // { Header: "Company", accessor: "Company" },
            // { Header: "Launch Date", accessor: "Launch Date" },


            { Header: "Product Name", accessor: "Product Name" },
            { Header: "UserName", accessor: "UserName" },
            { Header: "Email ", accessor: "Email" },
            { Header: "Company ", accessor: "Company" },
            { Header: "Designation ", accessor: "Designation" },
            { Header: "Role ", accessor: "Role" },
            { Header: "OrganisationSize ", accessor: "OrganisationSize" },
            { Header: "Mobile ", accessor: "Mobile" },
            { Header: "Follow_Date ", accessor: "Follow_Date" },
        ];


        dataToExport.forEach((item) => {
            item.m_following.forEach((ele) => {
                transformedData.push({

                    "Product Name": item?.ser_prod_name || "",
                    "UserName": ele?.m_user_follow_relation?.user_first_name + " " + ele?.m_user_follow_relation?.user_last_name,
                    "Email": ele?.m_user_follow_relation?.user_email,
                    "Company": ele?.m_user_follow_relation?.user_company_name,
                    "Designation": ele?.m_user_follow_relation?.user_designation,
                    "Role": ele?.follow_cu_relation?.m_role_relation?.role_name,
                    "OrganisationSize": ele?.m_user_follow_relation?.user_organisation_size,
                    "Mobile": ele?.m_user_follow_relation?.user_mobile,
                    "Follow_Date": moment(ele?.follow_created_at).format("DD/MM/YYYY"),

                });
            })


        });

        const headings = [columns.map((col) => col.Header)];

        exportToExcel(transformedData, headings, "DataExport.xlsx", "Sheet 1");
    };

    console.log("followData", followData)
    return (
        <>
            <div className="px-4 sm:px-6 lg:px-8">
                <Breadcrumb pages={pages} />
                <div className=" flex items-center justify-between my-2">
                    <div>
                        <h1 className="text-xl font-semibold text-gray-900">FollowUp List</h1>
                        <p className="mt-2 text-sm text-gray-700">A list of all the followup.</p>
                    </div>

                </div>
                <div className="pt-4 w-full shadow-sm rounded-md h-auto border-2 p-4">
                    <div className=" w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                        <div>
                            <label className="block text-sm font-medium leading-6 text-gray-900">
                                Search Product
                            </label>
                            <div>
                                <Select
                                    isMulti={true}
                                    classNamePrefix="select"
                                    defaultValue="Select"
                                    placeholder="Select"
                                    value={filters?.searchProduct}
                                    name="Product"
                                    className="basic-single sm:text-sm"
                                    options={productOption}
                                    onChange={(selectedOption) => {
                                        setFilters((prevFilters) =>
                                        ({
                                            ...prevFilters,
                                            searchProduct: selectedOption.filter((ele) => {
                                                return ({
                                                    ...ele,
                                                    label: ele.ser_prod_name,
                                                    value: ele.ser_id
                                                })
                                            }),
                                        })
                                        );
                                    }}
                                />
                            </div>
                        </div>
                        <div>
                            <label className="block text-sm font-medium leading-6 text-gray-900">
                                Start Date
                            </label>
                            <div className="flex">
                                <input
                                    type="date"
                                    name="date"
                                    value={startDate}
                                    onChange={(e) => {
                                        const startDate = e.target.value;
                                        setStartDate(startDate);
                                        setFilters((prevFilters) => ({
                                            ...prevFilters,
                                            startDate: startDate,
                                        }));
                                    }}
                                    className="block w-full rounded-md border-0 px-4 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                            </div>
                        </div>
                        <div>
                            <label className="block text-sm font-medium leading-6 text-gray-900">
                                End Date
                            </label>
                            <div className="flex">
                                <input
                                    type="date"
                                    name="date"
                                    value={endDate}
                                    onChange={(e) => {
                                        const endDate = e.target.value;
                                        setEndDate(endDate);
                                        setFilters((prevFilters) => ({
                                            ...prevFilters,
                                            endDate: endDate,
                                        }));
                                    }}
                                    className="block w-full rounded-md border-0 px-4 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                            </div>
                        </div>
                    </div>
                    <div className=" pt-4 ">
                        <button
                            onClick={clearForm}
                            type="button"
                            className="inline-flex items-center justify-center rounded-md border border-transparent bg-gradient-to-b from-[#fd6e1d] to-[#ef6122] px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-[#872341] focus:outline-none focus:ring-2 focus:ring-[#872341] focus:ring-offset-2 ml-auto"
                        >
                            Clear
                        </button>
                    </div>
                </div>
                <div className=" w-full mt-4 flex justify-end">
                    <button
                        type="button"
                        onClick={downloadExcel}
                        className="inline-flex items-center justify-center rounded-md border border-transparent bg-gradient-to-b from-[#fd6e1d] to-[#ef6122] px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-[#872341] focus:outline-none focus:ring-2 focus:ring-[#872341] focus:ring-offset-2 ml-auto"
                    >
                        Download Excel
                    </button>
                </div>
                {isLoading ? (
                    <FallingLinesLoader />
                ) : (
                    <Table
                        columns={followup_columns({ onModalOpen })}

                        data={Array.isArray(filterData) && filterData?.length > 0 ? filterData : filterData?.length <= 0 ? [] : followData}
                    />

                )}

            </div>
            <Transition.Root show={modalOpenFlage.isOpen} as={Fragment}>
                <Dialog
                    as="div"
                    className="relative z-10"
                    onClose={() => {
                        setmodalOpenFlage({
                            isOpen: false,
                            data: [],
                        });
                    }}
                >
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75" />
                    </Transition.Child>

                    <div className="fixed inset-0 z-10 overflow-y-auto">
                        <div className="flex items-end justify-center min-h-full p-4 text-center sm:items-center sm:p-0">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                enterTo="opacity-100 translate-y-0 sm:scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            >
                                <Dialog.Panel className="pointer-events-auto w-screen max-w-[500px]">
                                    <Table
                                        columns={followerUser_column()}
                                        data={modalOpenFlage?.data}
                                    />
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>
        </>
    )
}

export default FollowUpList;