import React, { Fragment, useEffect, useState } from "react";
import Breadcrumb from "../../../components/Breadcrumb";
import { useDispatch, useSelector } from "react-redux";
import { Link, useSearchParams } from "react-router-dom";
import {
  claims_columns,
  claims_products_columns,
  claims_view_columns,
  enquiry_columns,
  unclaims_products_columns,
} from "../../../components/tables/tableheader";
import Table from "../../../components/tables/table";
import { FallingLinesLoader } from "../../../components/spinners/Spinner";
import MasterServices from "../../../ApiServices/MasterServices";
import { Dialog, Transition } from "@headlessui/react";
import ConfirmationModal from "../../../components/ConfirmationModal";
import { toast } from "react-toast";
import { getAllUsers } from "../../../redux/user/actions";

import Select from "react-select";
import * as XLSX from "xlsx";
import { exportToExcel } from "../../../helper/commonHelper";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}
const Claim = () => {
  const pages = [{ title: "Claims", href: "/claims" }];
  const [loading, setLoading] = useState(true);
  const [activeTab, setActiveTab] = useState(0);
  const [claimedData, setClaimData] = useState([]);
console.log(claimedData, "Claimeddata ");
  const [modalOpenFlage, setmodalOpenFlage] = useState({
    isOpen: false,
    data: undefined,
  });
  const {
    user: { list: userList },
  } = useSelector((state) => state);
  const dispatch = useDispatch();
  const [confirmationModal, setConfirmationModal] = useState({
    status: false,
    id: null,
  });

  let [filteredData, setfilteredData] = useState([]);
  // console.log(filteredData);

  const [selectedClaimStatus, setselectedClaimStatus] = useState(null);

  const [filters, setFilters] = useState({
    claimStatus: null,
    startDate: null,
    endDate: null,
  });

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const [isUserModalOpen, setIsUserModalOpen] = useState(false);

  const [selectedUser, setSelectedUser] = useState(null);

  const [userDetailModalOpen, setUserDetailModalOpen] = useState({
    isOpen: false,
    data: null,
  });

  let SPNM_ACtive_Tab = 2;

  const getAllClaimData = async (payload) => {


    let myPayload = {
      prod_is_active: payload,
      // prod_is_approved: SPNM_ACtive_Tab === 0 ? 1 : 2,
      // prod_is_unclaimed: SPNM_ACtive_Tab === 1 ? 1 : 2,
      filter_id: SPNM_ACtive_Tab,


    };
    const response = await MasterServices.getAllClaim(myPayload);
    //console.log("getAllClaimData", response)
    if (response?.data?.data?.length > 0) {
      if(SPNM_ACtive_Tab ===2){

        setClaimData(response?.data?.data);
     
      }
      if(SPNM_ACtive_Tab ===0){
        // const inprocess = response?.data?.data;
        // const filteredInprocess = inprocess?.filter(
        //   (ele) => ele?.prod_is_claimed === true && ele?.prod_is_approved === true
        // );
        setClaimData(response?.data?.data)
        
      }
      if(SPNM_ACtive_Tab ===1){
        const inprocess = response?.data?.data;
        const filteredInprocess = inprocess?.filter(
          (ele) => ele?.prod_is_claimed === true && ele?.prod_is_approved === false
        );
        setClaimData(filteredInprocess)
        //console.log("filteredInprocess",filteredInprocess,inprocess)
      }
      setLoading(false);
    } else if (response?.data?.data?.length <= 0) {
      setClaimData([]);
      setfilteredData([])
      setLoading(false);
    }
    else {

      setClaimData([]);
      setfilteredData([])
      setLoading(false);
    }
    setLoading(false);
  };

  const approveClaim = async (row, setIsApproved, setLoader) => {
    // setLoading(true);
    setLoader(true);
    let body = {
      prod_id: row?.fk_product_id,
      prod_is_claimed: true,
      prod_is_approved: true,
      fk_user_id: row?.fk_user_id,
      pucm_id: row?.pucm_id,
    };
    await MasterServices.saveClaim(body)
      .then((res) => {
        const { data, status } = res;
        if (!data.status) {
          toast.error(data.message);
        } else {
          toast.success(data.message);
          setmodalOpenFlage((v) => ({
            ...v,
            data: v.data.map((v) => {
              let d = { ...v, prod_is_approved: true };
              if (d.pucm_id === row?.pucm_id) {
                d.pucm_is_approved = true;
              }
              return d;
            }),
          }));
          SPNM_ACtive_Tab  = 1
          getAllClaimData(3)
  
          // setIsApproved(true);
        }
        setLoader(false);

        setLoading(false);
      })
      .catch((err) => {
        toast.error(err.data?.message);

        setLoading(false);
      });
  };

  const onModalOpen = (data) => {
    //console.log("datadata", data);
    setmodalOpenFlage({
      isOpen: true,
      data: data
      // data: data.filter(v => v.pucm_is_approved && v.pucm_is_claimed),
    });
  };

  const onUserModalOpen = (userData) => {
    //console.log("User data received in parent:", userData);
    setSelectedUser(userData);
    // setIsUserModalOpen(true);
    setUserDetailModalOpen({
      isOpen: true,
      data: userData,
    });
  };

  const onApproveModalOpen = (row) => {
    setConfirmationModal({ id: row, status: true });
  };

  const onApprove = async (row, setIsApproved) => {
    await approveClaim(row, setIsApproved);
    await getAllClaimData();
  
    setConfirmationModal({ id: null, status: false });
  };

  useEffect(() => {
    clearForm();
    getAllClaimData(3);
    let payload = {
      user_is_hr_profesional: 2,
      user_is_hr_source_provider: 2,
      is_all_user: 1,
      user_is_cms: 2,
      user_is_active: 3,
    };
    dispatch(getAllUsers(payload));
  }, []);

  const tabs = [
    { name: "Claimed", href: "#", current: activeTab === 0 },
    { name: "Requests", href: "#", current: activeTab === 1 },
    { name: "Unclaimed", href: "#", current: activeTab === 2 },
  ];

  const claimStatusOptions = [
    { label: "Claimed", value: "claimed", index: 0 },
    { label: "In Process", value: "inprogress", index: 1 },
  ];
  const handleTabClick = async(index) => {
    //console.log("handleTabClick", index);
    setActiveTab(index);
    SPNM_ACtive_Tab = index;
    //console.log(SPNM_ACtive_Tab);
    await getAllClaimData(3);
  };
  //console.log("userList=>", userList);
  const handleDrawer = () => { };
  const handleSaveUserDrop = async (
    originalCell,
    newUserValue,
    selectedUserId,
    pucm_id
  ) => {
    let body = {
      pucm_id: pucm_id,
      fk_user_id: selectedUserId?.value,
    };
    await MasterServices.updateUserClaimed(body)
      .then((res) => {
        const { data, status } = res;
        if (!data.status) {
          toast.error(data.message);
        } else {
          toast.success(data.message);
          getAllClaimData(3);
        }
      })
      .catch((err) => {
        toast.error(err.data?.message);
      });
  };


  useEffect(() => {


    if (claimedData?.length > 0) {
      //console.log("")

      let mutableFilteredClaimsData = claimedData;
   
      // if (filters?.claimStatus) {
      //   mutableFilteredClaimsData = claimedData.filter(
      //     (product) => {


      //       if (filters?.claimStatus === "claimed") {
            
      //         return (product?.m_products_relation?.prod_is_claimed === true && product?.m_products_relation?.prod_is_approved === true);
         

      //       } else if (filters?.claimStatus === "inprogress") {
      //         // //console.log("product?.pucm_is_claimed",product?.pucm_is_claimed)
      //         // //console.log("product?.pucm_is_approved",product?.pucm_is_approved)
      //           return (product?.pucm_is_claimed === true && product?.pucm_is_approved === false);
           

      //       }
      //       return true
      //     }
      //   );


      // }

      if (filters?.startDate || filters?.endDate) {
     
        const startDate = filters?.startDate
          ? new Date(filters?.startDate)
          : new Date("1000-01-01");
        const endDate = filters?.endDate ? new Date(filters?.endDate) : new Date();

        startDate?.setHours(0, 0, 0, 0);
        endDate?.setHours(23, 59, 59, 999);


        mutableFilteredClaimsData = mutableFilteredClaimsData?.filter(
          (product) => {
            // //console.log(product);

            // const productDate = new Date(
            //   filters?.claimStatus 
            //     ? product?.prod_created_at
            //     : product?.prod_created_at
            //   // : product?.prod_created_at
            // );

            // const productDate = new Date(product?.prod_created_at)
             const productDate = new Date(
              filters?.claimStatus ==="claimed" 
                ? product?.pucm_created_at
                : product?.prod_created_at
              // : product?.prod_created_at
            );
            //console.log("filter", filters, "filterDate", productDate, "startDate", startDate, "endDate", endDate);
            return productDate >= startDate && productDate <= endDate;
          }
        );
        //console.log(mutableFilteredClaimsData?.length);
      }

      //console.log("mutableFilteredClaimsData", mutableFilteredClaimsData, "claimedData", claimedData)
      setClaimData(mutableFilteredClaimsData)
      // if(mutableFilteredClaimsData?.length>0){
      //   setfilteredData(mutableFilteredClaimsData)
      // }else if(mutableFilteredClaimsData?.length<=0){
      //   setfilteredData([])
      // }else{
      //   setfilteredData([])
      // }
      

    }

  }, [filters, claimedData]);

  useEffect(() => {
    //console.log("Filtered data updated:", filteredData);
  }, [filteredData]);
  
  const clearForm = () => {
    SPNM_ACtive_Tab = 2;
    setActiveTab(0);
    setfilteredData(null);
    getAllClaimData(3);
    setEndDate("");
    setStartDate("");
    setselectedClaimStatus(null);
    setFilters({
      claimStatus: null,
      startDate: null,
      endDate: null,
    });
  };


  const downloadExcel = () => {
    const dataToExport = filteredData || claimedData;

    let columns = [];
    let transformedData = [];

    if (activeTab === 1) {
      // Columns for activeTab === 1
      columns = [
        { Header: "Name", accessor: "Name" },
        { Header: "Email", accessor: "Email" },
        { Header: "Phone No", accessor: "Phone No" },
        { Header: "Designation", accessor: "Designation" },
        { Header: "Company", accessor: "Company" },
        {Header: "Role", accessor: "Role" },
        {Header: "OrganisationSize", accessor: "OrganisationSize" },
        { Header: "Product Name", accessor: "Product Name" },
        { Header: "Launch Date", accessor: "Launch Date" },
      ];

      // Flatten dataToExport for activeTab === 1
      dataToExport.forEach((item) => {
        if (item.product_user_claim_map && item.product_user_claim_map.length > 0) {
          item.product_user_claim_map.forEach((pucm) => {
            transformedData.push({
              "Name": `${pucm.m_user_pucm_relation?.user_first_name || "-"} ${pucm.m_user_pucm_relation?.user_last_name || "-"}`,
              "Email": pucm.m_user_pucm_relation?.user_email || "-",
              "Phone No": pucm.m_user_pucm_relation?.user_mobile || "-",
              "Designation": pucm.m_user_pucm_relation?.user_designation || "-",
              "Company": pucm.m_user_pucm_relation?.user_company_name || "-",
              "Role": pucm.pucm_uu_relation?.m_role_relation?.role_name ||"-",
              "OrganisationSize": pucm.m_user_pucm_relation?.user_organisation_size ||"-",
              // "Product": pucm.m_user_pucm_relation?.ser_prod_name || "",
              "Product Name": item.ser_prod_name || "-",
              "Launch Date": item.ser_prod_launch_date || "-",
            });
          });
        }
      });
    } else {
      // Default columns for other cases
      columns = [
        { Header: "Name", accessor: "Name" },
        { Header: "Email", accessor: "Email" },
        { Header: "Phone No", accessor: "Phone No" },
        { Header: "Designation", accessor: "Designation" },
        { Header: "Company", accessor: "Company" },
        {Header: "Role", accessor: "Role" },
        {Header: "OrganisationSize", accessor: "OrganisationSize" },
        { Header: "Product", accessor: "Product" },
        { Header: "Status", accessor: "Status" },
      ];

      // Transform data for default case
      transformedData = dataToExport.map((item) => ({
        "Name": `${item.m_user_pucm_relation?.user_first_name || ""} ${item.m_user_pucm_relation?.user_last_name || ""}`,
        "Email": item.m_user_pucm_relation?.user_email || "",
        "Phone No": item.m_user_pucm_relation?.user_mobile || "",
        "Designation": item.pucm_user_designation || "",
        "Company": item.pucm_user_company_name || "",
        "Role": item.pucm_uu_relation?.m_role_relation?.role_name ||"",
        "OrganisationSize": item?.m_user_pucm_relation?.user_organisation_size ||"-",
        "Product": item.m_products_relation?.ser_prod_name || "",
        "Status": item.pucm_is_approved ? "Approved" : "Not Approved",
      }));
    }

    const headings = [columns.map((col) => col.Header)];

    // Use the adjusted transformedData for the export
    exportToExcel(transformedData, headings, "DataExport.xlsx", "Sheet 1");
  };

  // Ensure you have defined activeTab, filteredData, claimedData, and the exportToExcel function appropriately for this to work.


  const onRevokeClaimedProduct = async (product) => {
    let body = {
      data: {
        is_claimed: false,
        is_approved: false,
      },
    };
    await MasterServices.deleteProduct({ id: product?.ser_id, body })
      .then((response) => {
        const { data, status } = response;

        if (data?.id) {
          toast.success("Successfully removed Claimed");
        } else {
          toast.error("Error");
        }
      })
      .catch((error) => { });
  };
  const onStatusUpdate = (item) => {
    let payload = {
      table_name: "product_user_claim_map",
      table_field: "pucm_id",
      table_field_val: item?.pucm_id,
      table_status_field: "pucm_is_deleted",
      table_status_val: item?.pucm_is_deleted ? false : true,
      table_text: "Claim",
      deleted_by_key: "pucm_updated_by",
      delete_active_txt: item?.pucm_is_deleted ? " Re-Install" : " Removed",
    };
    onRevokeClaimedProduct(item?.m_products_relation);
    MasterServices.removeMaster(payload).then((res) => {
      const { data, status, message } = res.data;
      if (status) {
        getAllClaimData(3);
        toast.success(message);
      } else {
        toast.error(message);
      }
    });
  };

  return (
    <>
      <div className="px-4 sm:px-6 lg:px-8">
        {/* <ConfirmationModal
          open={confirmationModal.status}
          setOpen={setConfirmationModal}
          onDelete={() => onDeleteRole(confirmationModal.role_id)}
        /> */}
        <ConfirmationModal
          open={confirmationModal.status}
          setOpen={setConfirmationModal}
          confirmationButtonText={"Approve"}
          onDelete={() => onApprove(confirmationModal.id)}
          data={confirmationModal}
        />
        <Breadcrumb pages={pages} />
        <div className="">
          <h1 className="text-xl font-semibold text-gray-900">Claims</h1>
          <p className="mt-2 text-sm text-gray-700">
            A list of all the Claims.
          </p>
        </div>
        <div className=" w-full shadow-sm rounded-md h-auto border-2 p-4">
          <div className=" w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            <div>
              <label className="block text-sm font-medium leading-6 text-gray-900">
                Claim Status
              </label>
              <div>
                <Select
                  classNamePrefix="select"
                  defaultValue={claimStatusOptions[0]}
                  placeholder="Select"
                  value={selectedClaimStatus}
                  // onBlur={handleBlur}
                  name="claimStatus"
                  className="basic-single sm:text-sm"
                  options={claimStatusOptions}
                  onChange={(selectedOption) => {
                    setselectedClaimStatus(selectedOption);
                    setFilters((prevFilters) => ({
                      ...prevFilters,
                      claimStatus: selectedOption.value,
                    }));
                    handleTabClick(selectedOption.index);
                  }}
                />{" "}
                {/* {errors.enterprise && touched.enterprise ? (
                    <p className="text-red-600 text-sm">{errors.enterprise}</p>
                  ) : null} */}
              </div>
            </div>
            <div>
              <label className="block text-sm font-medium leading-6 text-gray-900">
                Start Date
              </label>
              <div className="flex">
                <input
                  type="date"
                  name="date"
                  value={startDate}
                  onChange={(e) => {
                    const startDate = e.target.value;
                    setStartDate(startDate);
                    setFilters((prevFilters) => ({
                      ...prevFilters,
                      startDate: startDate,
                    }));
                  }}
                  className="block w-full rounded-md border-0 px-4 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>
            <div>
              <label className="block text-sm font-medium leading-6 text-gray-900">
                End Date
              </label>
              <div className="flex">
                <input
                  type="date"
                  name="date"
                  value={endDate}
                  onChange={(e) => {
                    const endDate = e.target.value;
                    setEndDate(endDate);
                    setFilters((prevFilters) => ({
                      ...prevFilters,
                      endDate: endDate,
                    }));
                  }}
                  className="block w-full rounded-md border-0 px-4 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>
          </div>
          <div className=" pt-4 ">
            <button
              onClick={clearForm}
              type="button"
              className="inline-flex items-center justify-center rounded-md border border-transparent bg-gradient-to-b from-[#fd6e1d] to-[#ef6122] px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-[#872341] focus:outline-none focus:ring-2 focus:ring-[#872341] focus:ring-offset-2 ml-auto"
            >
              Clear
            </button>
          </div>
        </div>
        <div className=" w-full mt-4 flex justify-end">
          <button
            type="button"
            onClick={downloadExcel}
            className="inline-flex items-center justify-center rounded-md border border-transparent bg-gradient-to-b from-[#fd6e1d] to-[#ef6122] px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-[#872341] focus:outline-none focus:ring-2 focus:ring-[#872341] focus:ring-offset-2 ml-auto"
          >
            Download Excel
          </button>
        </div>
        <div className="mt-4 flex justify-end gap-x-5">
          <div className="w-[300px]">
            {/* <div className="sm:hidden">
              <label htmlFor="tabs" className="sr-only">
                Select a tab
              </label>
              
              <select
                id="tabs"
                name="tabs"
                className="block w-full rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500"
                defaultValue={tabs.find((tab) => tab.current).name}
              >
                {tabs.map((tab) => (
                  <option key={tab.name}>{tab.name}</option>
                ))}
              </select>
            </div> */}
            {/* <div className="hidden sm:block ">
              <nav
                className="isolate flex divide-x divide-gray-200 rounded-lg shadow whitespace-nowrap"
                aria-label="Tabs"
              >
                {tabs.map((tab, tabIdx) => (
                  <a
                    key={tab.name}
                    href={tab.href}
                    onClick={() => handleTabClick(tabIdx)}
                    className={classNames(
                      tab.current
                        ? " bg-gradient-to-b from-[#DB7D0B] to-[#872341] text-white"
                        : "text-gray-500 hover:text-gray-700",
                      tabIdx === 0 ? "rounded-l-lg" : "",
                      tabIdx === tabs.length - 1 ? "rounded-r-lg" : "",
                      "group relative min-w-0 flex-1 overflow bg-white py-2.5 px-2 text-center text-sm font-medium hover:bg-gray-50 focus:z-10"
                    )}
                  >
                    <span>{tab.name}</span>
                    <span
                    aria-hidden="true"
                    className={classNames(
                      tab.current ? "bg-indigo-500" : "bg-transparent",
                      "absolute inset-x-0 bottom-0 h-0.5"
                    )}
                  />
                  </a>
                ))}
              </nav>
            </div> */}
          </div>
          {/* <div className="flex">
            <input
              type="date"
              name="date"
              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            />
          </div> */}
        </div>
        {activeTab == 0 && (
          <>
            {loading ? (
              <FallingLinesLoader />
            ) : (
              <Table
                columns={claims_columns({
                  onModalOpen,
                  onApproveModalOpen,
                  onStatusUpdate,
                  userList,
                  handleSaveUserDrop,
                })}
                data={
                  Array.isArray(filteredData) && filteredData?.length > 0
                    ? filteredData
                    : claimedData
                }
              />
            )}
          </>
        )}

        {activeTab == 1 && (
          <>
            {loading ? (
              <FallingLinesLoader />
            ) : (
              <Table
                columns={claims_products_columns({
                  onModalOpen,
                  onApproveModalOpen,
                })}
                data={
                  Array.isArray(filteredData) && filteredData?.length > 0
                    ? filteredData
                    : claimedData
                }
              />
            )}
          </>
        )}

        {activeTab == 2 && (
          <>
            {loading ? (
              <FallingLinesLoader />
            ) : (
              <Table
                columns={unclaims_products_columns({
                  onModalOpen,
                  onApproveModalOpen,
                })}
                data={
                  Array.isArray(filteredData) && filteredData?.length > 0
                    ? filteredData
                    : claimedData
                }
              />
            )}
          </>
        )}
      </div>

      <div>
        <Transition.Root show={modalOpenFlage.isOpen} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-10"
            onClose={() => {
              setmodalOpenFlage({
                isOpen: false,
                data: [],
              });
            }}
          >
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75" />
            </Transition.Child>

            <div className="fixed inset-0 z-10 overflow-y-auto">
              <div className="flex items-end justify-center min-h-full p-4 text-center sm:items-center sm:p-0">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                  <Dialog.Panel className="pointer-events-auto w-screen max-w-[500px]">
                    {activeTab == 0 && (
                      <div className="flex rounded-md h-full text-start pl-10 pr-4 flex-col divide-gray-200 bg-white shadow-xl pt-12">
                        <div className="h-0 flex-1 overflow-y-auto">
                          Name :{" "}
                          {(modalOpenFlage.data?.m_user_pucm_relation
                            ?.user_first_name || "") +
                            " " +
                            (modalOpenFlage.data?.m_user_pucm_relation
                              ?.user_last_name || "")}
                        </div>
                        <div className="h-0 flex-1 overflow-y-auto">
                          Email :{" "}
                          {modalOpenFlage.data?.m_user_pucm_relation
                            ?.user_email || ""}
                        </div>
                        <div className="h-0 flex-1 overflow-y-auto">
                          Phone No :{" "}
                          {modalOpenFlage.data?.m_user_pucm_relation
                            ?.user_mobile || ""}
                        </div>

                        {/* <div className="h-0 flex-1 overflow-y-auto">
                          Name :{" "}
                          {modalOpenFlage.data?.m_user_rr_relation.user_first_name
                           }
                        </div> */}
                        <div className="h-0 flex-1 overflow-y-auto">
                          Product :{" "}
                          {
                            modalOpenFlage.data?.m_products_relation
                              ?.ser_prod_name
                          }
                        </div>
                        <div className="h-0 flex-1 overflow-y-auto">
                          Designation :{" "}
                          {modalOpenFlage.data?.pucm_user_designation}
                        </div>

                        <div className="h-0 flex-1 overflow-y-auto">
                          Company :{" "}
                          {modalOpenFlage.data?.pucm_user_company_name}
                        </div>

                        <div className="flex flex-shrink-0 justify-end px-4 py-4">
                          {/* <button
                            type="button"
                            className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
                            onClick={() => {
                              setmodalOpenFlage({
                                isOpen: false,
                                data: undefined,
                              });
                            }}
                          >
                            Cancel
                          </button> */}
                          <div className="">
                            <button
                              type="button"
                              className="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                              onClick={() => {
                                setmodalOpenFlage({
                                  isOpen: false,
                                  data: undefined,
                                });
                              }}
                            >
                              Got it, thanks!
                            </button>
                          </div>
                        </div>
                      </div>
                    )}
                    {activeTab == 1 && (
                      <Table
                        columns={claims_view_columns({
                          onModalOpen,
                          approveClaim,
                          onUserModalOpen,
                        })}
                        data={modalOpenFlage.data}
                      />
                    )}
                    {userDetailModalOpen.isOpen && (
                      <div className="fixed inset-0 z-20 bg-black bg-opacity-50 flex justify-center items-center ">
                        <div className="flex rounded-md text-start pl-10 pr-4 flex-col w-[500px] divide-gray-200 bg-white shadow-xl pt-12">
                          <p className="h-0 flex-1 overflow-y-auto">
                            Name:
                            {/* {userDetailModalOpen.data?.m_user_pucm_relation?.user_first_name} {userDetailModalOpen.data?.m_user_pucm_relation?.user_last_name} */}
                            {(userDetailModalOpen.data?.m_user_pucm_relation
                              ?.user_first_name || "") +
                              " " +
                              (userDetailModalOpen.data?.m_user_pucm_relation
                                ?.user_last_name || "")}
                          </p>
                          <p className="h-0 flex-1 overflow-y-auto ">
                            Email:{" "}
                            {
                              userDetailModalOpen.data?.m_user_pucm_relation
                                ?.user_email
                            }
                          </p>
                          <p className="h-0 flex-1 overflow-y-auto ">
                            Phone No:{" "}
                            {
                              userDetailModalOpen.data?.m_user_pucm_relation
                                ?.user_mobile
                            }
                          </p>
                          <p className="h-0 flex-1 overflow-y-auto">
                            Product:{" "}
                            {
                              userDetailModalOpen.data?.m_products_relation
                                ?.ser_prod_name
                            }
                          </p>
                          <p className="h-0 flex-1 overflow-y-auto ">
                            Designation:{" "}
                            {userDetailModalOpen.data?.pucm_user_designation}
                          </p>
                          <p className="h-0 flex-1 overflow-y-auto">
                            Company:{" "}
                            {userDetailModalOpen.data?.pucm_user_company_name}
                          </p>
                          <div className=" py-3 sm:px-6 flex flex-row-reverse">
                            <button
                              type="button"
                              className="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                              onClick={() =>
                                setUserDetailModalOpen({
                                  isOpen: false,
                                  data: null,
                                })
                              }
                            >
                              Got it, thanks!
                            </button>
                          </div>
                        </div>
                      </div>
                    )}
                    {activeTab == 2 && (
                      <div className="flex h-full flex-col divide-gray-200 bg-white shadow-xl pt-12">
                        <div className="h-0 flex-1 overflow-y-auto">
                          Product Name : {modalOpenFlage?.data?.ser_prod_name}
                        </div>
                        <div className="h-0 flex-1 overflow-y-auto">
                          Headline : {modalOpenFlage?.data?.ser_prod_headline}
                        </div>
                        <div className="h-0 flex-1 overflow-y-auto">
                          Description :{" "}
                          {modalOpenFlage?.data?.ser_prod_short_description}
                        </div>
                        <div className="flex flex-shrink-0 justify-end px-4 py-4">
                          <button
                            type="button"
                            className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
                            onClick={() => {
                              setmodalOpenFlage({
                                isOpen: false,
                                data: undefined,
                              });
                            }}
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    )}
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition.Root>
      </div>
    </>
  );
};

export default Claim;
