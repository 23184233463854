import { Link, useNavigate } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import { capitalise } from "../../utilities/utilities";
import { Switch } from "@headlessui/react";
import "./style.css"; // import CSS file
import moment from "moment";
import { PlusCircleIcon } from "@heroicons/react/24/outline";
import { PlusIcon } from "@heroicons/react/20/solid";
import { clearAllListeners } from "@reduxjs/toolkit";
import { FallingLinesLoader, OvalLoader } from "../spinners/Spinner";
import { FidgetSpinner } from "react-loader-spinner";
import Select from "react-select";
import makeAnimated from "react-select/animated";
function userStatusToggle(...classes) {
  return classes.filter(Boolean).join(" ");
}

function magazineStatusToggle(...classes) {
  return classes.filter(Boolean).join(" ");
}
function magazineIssueStatusToggle(...classes) {
  return classes.filter(Boolean).join(" ");
}

function productStatusToggle(...classes) {
  return classes.filter(Boolean).join(" ");
}
const animatedComponents = makeAnimated();
export const user_columns = ({
  onDeleteOpen,
  handleDrawer,
  allowedActions,
}) => [
    {
      Header: "ID",
      accessor: "user_id",
    },

    {
      Header: "Name",
      accessor: (d) =>
        capitalise(d.user_first_name) + " " + capitalise(d.user_last_name),
    },
    {
      Header: "Email",
      accessor: "user_email",
    },
    {
      Header: "Role Type",
      accessor: (d) => capitalise(d?.m_role_relation?.role_name),
    },

    {
      Header: "Created At",
      accessor: "user_updated_at",
      Cell: (cell) => {
        const cellID = cell?.row?.original?.user_updated_at;

        return (
          <span className="sm:text-sm text-gray-500">
            {new Date(cellID).toLocaleDateString("en-US", {
              timeZone: "Asia/Kolkata",
            })}
          </span>
        );
      },
    },
    // {
    //   Header: "Status",
    //   accessor: (d) => (d.user_is_active == true ? "Active" : "Inactive"),
    //   Cell: (cell) => {
    //     const cellId = cell.row.original.user_id;
    //     const enabled = cell.row.original.user_is_active;
    //     return (
    //       <>
    //         {allowedActions.filter((data) => data.permission_id == 5)?.length > 0 ? (
    //           <Switch
    //             checked={enabled}
    //             onChange={() => {
    //               onDeleteOpen(cellId, cell.row.original.user_is_active);
    //             }}
    //             className={userStatusToggle(
    //               enabled ? "bg-[#872341]" : "bg-gray-200",
    //               "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
    //             )}
    //           >
    //             <span className="sr-only">Use setting</span>
    //             <span
    //               aria-hidden="true"
    //               className={userStatusToggle(
    //                 enabled ? "translate-x-5" : "translate-x-0",
    //                 "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
    //               )}
    //             />
    //           </Switch>) : null}
    //       </>
    //     );
    //   },
    // },
    {
      Header: "Action",
      accessor: "action",
      Cell: (cell) => {
        const cellId = cell.row.original.user_id;
        const isBtn = cell.row.original.isBtn;
        return (
          <>
            {(allowedActions.filter((data) => data.permission_id == 3)?.length >
              0 &&
              isBtn) ||
              localStorage.getItem("user_id") == cellId ? (
              <Link
                onClick={() => handleDrawer("edit", cellId, cell.row.original)}
                className="inline-flex px-2 ml-3 text-xs leading-5 rounded-full text-cyan-600 bg-cyan-100"
              >
                <button
                  className="text-cyan-900 "
                  size="default"
                  variant="outlined"
                >
                  Edit<span className="sr-only">, </span>
                </button>
              </Link>
            ) : null}

            {allowedActions.filter((data) => data.permission_id == 4)?.length >
              0 && isBtn ? (
              <span className="inline-flex px-2 ml-3 text-xs leading-5 text-red-600 bg-red-100 rounded-full">
                <button
                  onClick={() => {
                    onDeleteOpen(cellId);
                  }}
                  size="default"
                  variant="outlined"
                >
                  Inactive
                </button>
              </span>
            ) : null}
          </>
        );
      },
    },
  ];

export const role_columns = ({
  onDeleteOpen,
  handleDrawer,
  handleSaveResult,
  handleUpdateResult,
  saveMaxDiscountValue,
  allowedActions,
}) => [
    {
      Header: "Role Id",
      accessor: "role_id",
    },
    {
      Header: "Role Name",
      accessor: (d) => capitalise(d.role_name),
    },
    {
      Header: "Action",
      accessor: "action",
      Cell: (cell) => {
        const cellId = cell.row.original.role_id;
        const isBtn = cell.row.original.isBtn;
        return (
          <>
            {/* {isBtn && ( */}
            {allowedActions.filter((data) => data.permission_id == 8)?.length >
              0 && isBtn ? (
              <Link
                onClick={() => handleDrawer("edit", cellId, cell.row.original)}
                className="inline-flex px-2 ml-3 text-xs leading-5 rounded-full text-cyan-600 bg-cyan-100"
              >
                <button
                  className="text-cyan-900 "
                  size="default"
                  variant="outlined"
                >
                  Edit
                </button>
              </Link>
            ) : null}
            {/* // )} */}
            {/* {isBtn && ( */}
            {allowedActions.filter((data) => data.permission_id == 9)?.length >
              0 && isBtn ? (
              <Link
                to={`/role/${cellId}`}
                onClick={() => handleDrawer("edit", cellId, cell.row.original)}
                className="inline-flex px-2 ml-3 text-xs leading-5 rounded-full text-cyan-600 bg-cyan-100"
              >
                <button
                  className="text-cyan-900 "
                  size="default"
                  variant="outlined"
                >
                  Edit Permissions
                </button>
              </Link>
            ) : null}
            {/* )} */}
            {/* {isBtn && ( */}
            {allowedActions.filter((data) => data.permission_id == 11)?.length >
              0 && isBtn ? (
              <span className="inline-flex px-2 ml-3 text-xs leading-5 text-red-600 bg-red-100 rounded-full">
                <button
                  onClick={() => {
                    onDeleteOpen(cellId);
                  }}
                  size="default"
                  variant="outlined"
                >
                  Inactive
                </button>
              </span>
            ) : null}
            {/* )} */}

            {/* {isBtn && (<Link
              onClick={() => handleDrawer("edit", cellId, cell.row.original)}
              className="inline-flex px-2 ml-3 text-xs leading-5 rounded-full text-cyan-600 bg-cyan-100"
            >
              <button
                className="text-cyan-900 "
                size="default"
                variant="outlined"
              >
                Edit
              </button>
            </Link>)}

            {isBtn && (<Link
              to={`/role/${cellId}`}
              onClick={() => handleDrawer("edit", cellId, cell.row.original)}
              className="inline-flex px-2 ml-3 text-xs leading-5 rounded-full text-cyan-600 bg-cyan-100"
            >
              <button
                className="text-cyan-900 "
                size="default"
                variant="outlined"
              >
                Edit Permissions
              </button>
            </Link>)}

            {isBtn && (<span className="inline-flex px-2 ml-3 text-xs leading-5 text-red-600 bg-red-100 rounded-full">
              <button
                onClick={() => {
                  onDeleteOpen(cellId);
                }}
                size="default"
                variant="outlined"
              >
                Delete
              </button>
            </span>)} */}
          </>
        );
      },
    },
  ];

export const tag_columns = ({
  // onDeleteOpen,
  handleDrawer,
  onDeleteOpen,
  // handleSaveResult,
  // handleUpdateResult,
  // saveMaxDiscountValue,
  allowedActions,
}) => [
    {
      Header: "Id",
      accessor: "id",
    },
    {
      Header: "Name",
      accessor: (d) => capitalise(d.attributes.name),
    },
    {
      Header: "Status",
      accessor: "publishedAt",
      // accessor: (d) => capitalise(d.attributes.publishedAt),
      Cell: (cell) => {
        const publishedAt = cell.row.original.attributes.publishedAt;
        return (
          <>
            <span className="text-gray-500 sm:text-sm">
              {publishedAt ? "Published" : "Draft"}
            </span>
          </>
        );
      },
    },
    {
      Header: "Action",
      accessor: "action",
      Cell: (cell) => {
        const cellId = cell.row.original.id;
        //
        return (
          <>
            {allowedActions.filter((data) => data.permission_id == 29)?.length >
              0 ? (
              <Link
                onClick={() => handleDrawer("edit", cellId, cell.row.original)}
                className="inline-flex px-2 ml-3 text-xs leading-5 rounded-full text-cyan-600 bg-cyan-100"
              >
                <button
                  className="text-cyan-900 "
                  size="default"
                  variant="outlined"
                >
                  Edit
                </button>
              </Link>
            ) : null}
            {allowedActions.filter((data) => data.permission_id == 30)?.length >
              0 ? (
              <Link
                onClick={() => onDeleteOpen(cell.row.original)}
                className={`"inline-flex px-2 ml-3 text-xs leading-5 rounded-full  ${cell?.row?.original?.attributes?.publishedAt
                  ? "bg-yellow-200 text-yellow-600"
                  : "bg-red-200 text-red-600"
                  }"`}
              >
                {cell?.row?.original?.attributes?.publishedAt ? (
                  <button
                    className="text-yellow-900  "
                    size="default"
                    variant="outlined"
                  >
                    Inactive
                  </button>
                ) : (
                  <button
                    className="text-red-900 "
                    size="default"
                    variant="outlined"
                  >
                    Active
                  </button>
                )}
              </Link>
            ) : null}
          </>
        );
      },
    },
  ];

export const buisness_objective_columns = ({
  onDeleteOpen,
  handleDrawer,
  // handleSaveResult,
  // handleUpdateResult,
  // saveMaxDiscountValue,
  allowedActions,
}) => [
    {
      Header: "Id",
      accessor: "id",
    },
    {
      Header: "Name",
      accessor: (d) => capitalise(d.attributes.name),
      accessor: (d) => {
        return (
          <div style={{ width: 200, whiteSpace: "pre-line" }}>
            {d.attributes.name}
          </div>
        );
      },
    },
    {
      Header: "Status",
      accessor: "publishedAt",
      // accessor: (d) => capitalise(d.attributes.publishedAt),
      Cell: (cell) => {
        const publishedAt = cell.row.original.attributes.publishedAt;
        return (
          <>
            <span className="text-gray-500 sm:text-sm">
              {publishedAt ? "Published" : "Draft"}
            </span>
          </>
        );
      },
    },
    // {
    //   Header: "objective id",
    //   accessor: (d) => capitalise(d.attributes.business_objective_id),
    // },
    //  {
    //   Header: "Status",
    //   accessor: "publishedAt",
    //    Cell: (cell) => {
    //      const publishedAt = cell.row.original.attributes.publishedAt;

    //     return (
    //       <>
    //       <span className="text-gray-500 sm:text-sm">{publishedAt?'Published':'Draft'}</span>

    //       </>)}
    // },
    {
      Header: "Action",
      accessor: "action",
      Cell: (cell) => {
        const cellId = cell.row.original.id;
        return (
          <>
            {allowedActions.filter((data) => data.permission_id == 34)?.length >
              0 ? (
              <Link
                onClick={() => handleDrawer("edit", cellId, cell.row.original)}
                className="inline-flex px-2 ml-3 text-xs leading-5 rounded-full text-cyan-600 bg-cyan-100"
              >
                <button
                  className="text-cyan-900 "
                  size="default"
                  variant="outlined"
                >
                  Edit
                </button>
              </Link>
            ) : null}
            {allowedActions.filter((data) => data.permission_id == 35)?.length >
              0 ? (
              <Link
                onClick={() => onDeleteOpen(cell.row.original)}
                className={`"inline-flex px-2 ml-3 text-xs leading-5 rounded-full  ${cell?.row?.original?.attributes?.publishedAt
                  ? "bg-yellow-200 text-yellow-600"
                  : "bg-red-200 text-red-600"
                  }"`}
              >
                {cell?.row?.original?.attributes?.publishedAt ? (
                  <button
                    className="text-yellow-900  "
                    size="default"
                    variant="outlined"
                  >
                    Inactive
                  </button>
                ) : (
                  <button
                    className="text-red-900 "
                    size="default"
                    variant="outlined"
                  >
                    Active
                  </button>
                )}
              </Link>
            ) : null}
          </>
        );
      },
    },
  ];

export const categories_columns = ({
  onDeleteOpen,
  handleDrawer,
  // handleSaveResult,
  // handleUpdateResult,
  // saveMaxDiscountValue,
  allowedActions,
}) => [
    {
      Header: "Id",
      accessor: "id",
    },
    {
      Header: "Name",
      accessor: (d) => capitalise(d.attributes.name),
    },
    {
      Header: "Status",
      accessor: "publishedAt",
      Cell: (cell) => {
        const publishedAt = cell.row.original.attributes.publishedAt;

        return (
          <>
            <span className="text-gray-500 sm:text-sm">
              {publishedAt ? "Published" : "Draft"}
            </span>
          </>
        );
      },
    },
    {
      Header: "Action",
      accessor: "action",
      Cell: (cell) => {
        const cellId = cell.row.original.id;
        return (
          <>
            {allowedActions.filter((data) => data.permission_id == 39)?.length >
              0 ? (
              <Link
                onClick={() => handleDrawer("edit", cellId, cell.row.original)}
                className="inline-flex px-2 ml-3 text-xs leading-5 rounded-full text-cyan-600 bg-cyan-100"
              >
                <button
                  className="text-cyan-900 "
                  size="default"
                  variant="outlined"
                >
                  Edit
                </button>
              </Link>
            ) : null}
            {allowedActions.filter((data) => data.permission_id == 40)?.length >
              0 ? (
              <Link
                onClick={() => onDeleteOpen(cell.row.original)}
                className={`"inline-flex px-2 ml-3 text-xs leading-5 rounded-full  ${cell?.row?.original?.attributes?.publishedAt
                  ? "bg-yellow-200 text-yellow-600"
                  : "bg-red-200 text-red-600"
                  }"`}
              >
                {cell?.row?.original?.attributes?.publishedAt ? (
                  <button
                    className="text-yellow-900  "
                    size="default"
                    variant="outlined"
                  >
                    Inactive
                  </button>
                ) : (
                  <button
                    className="text-red-900 "
                    size="default"
                    variant="outlined"
                  >
                    Active
                  </button>
                )}
              </Link>
            ) : null}
          </>
        );
      },
    },
  ];

export const sub_categories_columns = ({
  onDeleteOpen,
  handleDrawer,
  // handleSaveResult,
  // handleUpdateResult,
  // saveMaxDiscountValue,
  allowedActions,
}) => [
    {
      Header: "Id",
      accessor: "id",
    },
    {
      Header: "Name",
      accessor: (d) => capitalise(d.attributes.name),
    },
    {
      Header: "Status",
      accessor: "publishedAt",
      Cell: (cell) => {
        const publishedAt = cell.row.original.attributes.publishedAt;

        return (
          <>
            <span className="text-gray-500 sm:text-sm">
              {publishedAt ? "Published" : "Draft"}
            </span>
          </>
        );
      },
    },
    {
      Header: "Action",
      accessor: "action",
      Cell: (cell) => {
        const cellId = cell.row.original.id;
        return (
          <>
            {allowedActions.filter((data) => data.permission_id == 44)?.length >
              0 ? (
              <Link
                onClick={() => handleDrawer("edit", cellId, cell.row.original)}
                className="inline-flex px-2 ml-3 text-xs leading-5 rounded-full text-cyan-600 bg-cyan-100"
              >
                <button
                  className="text-cyan-900 "
                  size="default"
                  variant="outlined"
                >
                  Edit
                </button>
              </Link>
            ) : null}
            {allowedActions.filter((data) => data.permission_id == 45)?.length >
              0 ? (
              <Link
                onClick={() => onDeleteOpen(cell.row.original)}
                className={`"inline-flex px-2 ml-3 text-xs leading-5 rounded-full  ${cell?.row?.original?.attributes?.publishedAt
                  ? "bg-yellow-200 text-yellow-600"
                  : "bg-red-200 text-red-600"
                  }"`}
              >
                {cell?.row?.original?.attributes?.publishedAt ? (
                  <button
                    className="text-yellow-900  "
                    size="default"
                    variant="outlined"
                  >
                    Inactive
                  </button>
                ) : (
                  <button
                    className="text-red-900 "
                    size="default"
                    variant="outlined"
                  >
                    Active
                  </button>
                )}
              </Link>
            ) : null}
          </>
        );
      },
    },
  ];

export const client_columns = ({
  onDeleteOpen,
  handleDrawer,
  // handleSaveResult,
  // handleUpdateResult,
  // saveMaxDiscountValue,
  allowedActions,
}) => [
    {
      Header: "Id",
      accessor: "id",
    },
    {
      Header: "Name",
      accessor: (d) => capitalise(d.attributes.name),
    },
    {
      Header: "Status",
      accessor: "publishedAt",
      Cell: (cell) => {
        const publishedAt = cell.row.original.attributes.publishedAt;

        return (
          <>
            <span className="text-gray-500 sm:text-sm">
              {publishedAt ? "Published" : "Draft"}
            </span>
          </>
        );
      },
    },
    {
      Header: "Action",
      accessor: "action",
      Cell: (cell) => {
        const cellId = cell.row.original.id;
        return (
          <>
            {allowedActions.filter((data) => data.permission_id == 49)?.length >
              0 ? (
              <Link
                onClick={() => handleDrawer("edit", cellId, cell.row.original)}
                className="inline-flex px-2 ml-3 text-xs leading-5 rounded-full text-cyan-600 bg-cyan-100"
              >
                <button
                  className="text-cyan-900 "
                  size="default"
                  variant="outlined"
                >
                  Edit
                </button>
              </Link>
            ) : null}
            {allowedActions.filter((data) => data.permission_id == 50)?.length >
              0 ? (
              <Link
                onClick={() => onDeleteOpen(cell.row.original)}
                className={`"inline-flex px-2 ml-3 text-xs leading-5 rounded-full  ${cell?.row?.original?.attributes?.publishedAt
                  ? "bg-yellow-200 text-yellow-600"
                  : "bg-red-200 text-red-600"
                  }"`}
              >
                {cell?.row?.original?.attributes?.publishedAt ? (
                  <button
                    className="text-yellow-900  "
                    size="default"
                    variant="outlined"
                  >
                    Inactive
                  </button>
                ) : (
                  <button
                    className="text-red-900 "
                    size="default"
                    variant="outlined"
                  >
                    Active
                  </button>
                )}
              </Link>
            ) : null}
          </>
        );
      },
    },
  ];

export const hcm_columns = ({
  onDeleteOpen,
  handleDrawer,
  // handleSaveResult,
  // handleUpdateResult,
  // saveMaxDiscountValue,
  allowedActions,
}) => [
    {
      Header: "Id",
      accessor: "id",
    },
    {
      Header: "Name",
      accessor: (d) => capitalise(d.attributes.name),
    },
    {
      Header: "Status",
      accessor: "publishedAt",
      Cell: (cell) => {
        const publishedAt = cell.row.original.attributes.publishedAt;

        return (
          <>
            <span className="text-gray-500 sm:text-sm">
              {publishedAt ? "Published" : "Draft"}
            </span>
          </>
        );
      },
    },
    {
      Header: "Action",
      accessor: "action",
      Cell: (cell) => {
        const cellId = cell.row.original.id;
        return (
          <>
            {allowedActions.filter((data) => data.permission_id == 65)?.length >
              0 ? (
              <Link
                onClick={() => handleDrawer("edit", cellId, cell.row.original)}
                className="inline-flex px-2 ml-3 text-xs leading-5 rounded-full text-cyan-600 bg-cyan-100"
              >
                <button
                  className="text-cyan-900 "
                  size="default"
                  variant="outlined"
                >
                  Edit
                </button>
              </Link>
            ) : null}
            {allowedActions.filter((data) => data.permission_id == 66)?.length >
              0 ? (
              <Link
                onClick={() => onDeleteOpen(cell.row.original)}
                className={`"inline-flex px-2 ml-3 text-xs leading-5 rounded-full  ${cell?.row?.original?.attributes?.publishedAt
                  ? "bg-yellow-200 text-yellow-600"
                  : "bg-red-200 text-red-600"
                  }"`}
              >
                {cell?.row?.original?.attributes?.publishedAt ? (
                  <button
                    className="text-yellow-900  "
                    size="default"
                    variant="outlined"
                  >
                    Inactive
                  </button>
                ) : (
                  <button
                    className="text-red-900 "
                    size="default"
                    variant="outlined"
                  >
                    Active
                  </button>
                )}
              </Link>
            ) : null}
          </>
        );
      },
    },
  ];

//         {/* {isBtn && (<Link
//           onClick={() => handleDrawer("edit", cellId, cell.row.original)}
//           className="inline-flex px-2 ml-3 text-xs leading-5 rounded-full text-cyan-600 bg-cyan-100"
//         >
//           <button
//             className="text-cyan-900 "
//             size="default"
//             variant="outlined"
//           >
//             Edit
//           </button>
//         </Link>)}

//         {isBtn && (<Link
//           to={`/role/${cellId}`}
//           onClick={() => handleDrawer("edit", cellId, cell.row.original)}
//           className="inline-flex px-2 ml-3 text-xs leading-5 rounded-full text-cyan-600 bg-cyan-100"
//         >
//           <button
//             className="text-cyan-900 "
//             size="default"
//             variant="outlined"
//           >
//             Edit Permissions
//           </button>
//         </Link>)}

export const company_columns = ({
  onDeleteOpen,
  handleDrawer,
  // handleSaveResult,
  // handleUpdateResult,
  // saveMaxDiscountValue,
  allowedActions,
}) => [
    {
      Header: "Id",
      accessor: "id",
    },
    {
      Header: "Name",
      accessor: (d) => capitalise(d.attributes.name),
    },
    {
      Header: "Status",
      accessor: "publishedAt",
      Cell: (cell) => {
        const publishedAt = cell.row.original.attributes.publishedAt;

        return (
          <>
            <span className="text-gray-500 sm:text-sm">
              {publishedAt ? "Published" : "Draft"}
            </span>
          </>
        );
      },
    },
    {
      Header: "Action",
      accessor: "action",
      Cell: (cell) => {
        const cellId = cell.row.original.id;
        return (
          <>
            {allowedActions.filter((data) => data.permission_id == 54)?.length >
              0 ? (
              <Link
                onClick={() => handleDrawer("edit", cellId, cell.row.original)}
                className="inline-flex px-2 ml-3 text-xs leading-5 rounded-full text-cyan-600 bg-cyan-100"
              >
                <button
                  className="text-cyan-900 "
                  size="default"
                  variant="outlined"
                >
                  Edit
                </button>
              </Link>
            ) : null}
            {allowedActions.filter((data) => data.permission_id == 55)?.length >
              0 ? (
              <Link
                onClick={() => onDeleteOpen(cell.row.original)}
                className={`"inline-flex px-2 ml-3 text-xs leading-5 rounded-full  ${cell?.row?.original?.attributes?.publishedAt
                  ? "bg-yellow-200 text-yellow-600"
                  : "bg-red-200 text-red-600"
                  }"`}
              >
                {cell?.row?.original?.attributes?.publishedAt ? (
                  <button
                    className="text-yellow-900  "
                    size="default"
                    variant="outlined"
                  >
                    Inactive
                  </button>
                ) : (
                  <button
                    className="text-red-900 "
                    size="default"
                    variant="outlined"
                  >
                    Active
                  </button>
                )}
              </Link>
            ) : null}
          </>
        );
      },
    },
  ];

//         {/* {isBtn && (<Link
//           onClick={() => handleDrawer("edit", cellId, cell.row.original)}
//           className="inline-flex px-2 ml-3 text-xs leading-5 rounded-full text-cyan-600 bg-cyan-100"
//         >
//           <button
//             className="text-cyan-900 "
//             size="default"
//             variant="outlined"
//           >
//             Edit
//           </button>
//         </Link>)}

//         {isBtn && (<Link
//           to={`/role/${cellId}`}
//           onClick={() => handleDrawer("edit", cellId, cell.row.original)}
//           className="inline-flex px-2 ml-3 text-xs leading-5 rounded-full text-cyan-600 bg-cyan-100"
//         >
//           <button
//             className="text-cyan-900 "
//             size="default"
//             variant="outlined"
//           >
//             Edit Permissions
//           </button>
//         </Link>)}

//         {isBtn && (<span className="inline-flex px-2 ml-3 text-xs leading-5 text-red-600 bg-red-100 rounded-full">
//           <button
//             onClick={() => {
//               onDeleteOpen(cellId);
//             }}
//             size="default"
//             variant="outlined"
//           >
//             Delete
//           </button>
//         </span>)} */}
//       </>
//     );
//   },
// },
// ];

export const searcher_product_columns = ({
  // onDeleteOpen,
  handleDrawer,
  // handleSaveResult,
  // handleUpdateResult,
  // saveMaxDiscountValue,
}) => [
    {
      Header: "Id",
      accessor: "id",
    },
    {
      Header: "Name",
      accessor: (d) => capitalise(d.attributes.Name),
    },
    // {
    //   Header: "Action",
    //   accessor: "action",
    //   Cell: (cell) => {
    //     const cellId = cell.row.original.id;
    //     return (
    //       <>
    //         <Link
    //           onClick={() => handleDrawer("edit", cellId, cell.row.original)}
    //           className="inline-flex px-2 ml-3 text-xs leading-5 rounded-full text-cyan-600 bg-cyan-100"
    //         >
    //           <button
    //             className="text-cyan-900 "
    //             size="default"
    //             variant="outlined"
    //           >
    //             Edit
    //           </button>
    //         </Link>
    //       </>
    //     );
    //   },
    // },
  ];

export const country_columns = ({ onDeleteOpen, handleDrawer }) => [
  {
    Header: "Country",
    accessor: (d) => capitalise(d.country_name),
  },
  {
    Header: "Country Code",
    accessor: "country_code",
  },

  {
    Header: "Country ISD Code",
    accessor: "country_isd_code",
  },

  {
    Header: "Action",
    accessor: "action",
    Cell: (cell) => {
      const cellId = cell.row.original.country_id;
      const country_is_edit = cell.row.original.country_is_edit;
      return (
        <>
          {country_is_edit && (
            <Link
              onClick={() => handleDrawer("edit", cellId, cell.row.original)}
              className="inline-flex px-2 ml-3 text-xs leading-5 rounded-full text-cyan-600 bg-cyan-100"
            >
              <button
                className="text-cyan-900 "
                size="default"
                variant="outlined"
              >
                Edit<span className="sr-only">, </span>
              </button>
            </Link>
          )}

          {country_is_edit && (
            <span className="inline-flex px-2 ml-3 text-xs leading-5 text-red-600 bg-red-100 rounded-full">
              <button
                onClick={() => {
                  onDeleteOpen(cellId);
                }}
                size="default"
                variant="outlined"
              >
                Delete
                <span className="sr-only">, </span>
              </button>
            </span>
          )}
        </>
      );
    },
  },
];

export const gender_columns = ({ onDeleteOpen, handleDrawer }) => [
  {
    Header: "Gender",
    accessor: (d) => capitalise(d.gender_name),
  },
  {
    Header: "Action",
    accessor: "action",
    Cell: (cell) => {
      const cellId = cell.row.original.gender_id;
      return (
        <>
          <Link
            onClick={() => handleDrawer("edit", cellId, cell.row.original)}
            className="inline-flex px-2 ml-3 text-xs leading-5 rounded-full text-cyan-600 bg-cyan-100"
          >
            <button
              className="text-cyan-900 "
              size="default"
              variant="outlined"
            >
              Edit<span className="sr-only">, </span>
            </button>
          </Link>

          <span className="inline-flex px-2 ml-3 text-xs leading-5 text-red-600 bg-red-100 rounded-full">
            <button
              onClick={() => {
                onDeleteOpen(cellId);
              }}
              size="default"
              variant="outlined"
            >
              Delete
              <span className="sr-only">, </span>
            </button>
          </span>
        </>
      );
    },
  },
];

export const state_columns = ({ onDeleteOpen, handleDrawer }) => [
  {
    Header: "State Name",
    accessor: (d) => capitalise(d.state_name),
  },
  {
    Header: "Country Name",
    accessor: (d) => capitalise(d.m_country_relation.country_name),
  },
  {
    Header: "Action",
    accessor: "action",
    Cell: (cell) => {
      const cellId = cell.row.original.state_id;
      return (
        <>
          <Link
            onClick={() => handleDrawer("edit", cellId, cell.row.original)}
            className="inline-flex px-2 ml-3 text-xs leading-5 rounded-full text-cyan-600 bg-cyan-100"
          >
            <button
              className="text-cyan-900 "
              size="default"
              variant="outlined"
            >
              Edit<span className="sr-only">, </span>
            </button>
          </Link>

          <span className="inline-flex px-2 ml-3 text-xs leading-5 text-red-600 bg-red-100 rounded-full">
            <button
              onClick={() => {
                onDeleteOpen(cellId);
              }}
              size="default"
              variant="outlined"
            >
              Delete
              <span className="sr-only">, </span>
            </button>
          </span>
        </>
      );
    },
  },
];

export const city_columns = ({ onDeleteOpen, handleDrawer }) => [
  {
    Header: "City Name",
    accessor: (d) => capitalise(d.city_name),
  },
  {
    Header: "State",
    accessor: (d) => capitalise(d.m_state_relation.state_name),
  },
  {
    Header: "Action",
    accessor: "action",
    Cell: (cell) => {
      const cellId = cell.row.original.city_id;
      return (
        <>
          <Link
            onClick={() => handleDrawer("edit", cellId, cell.row.original)}
            className="inline-flex px-2 ml-3 text-xs leading-5 rounded-full text-cyan-600 bg-cyan-100"
          >
            <button
              className="text-cyan-900 "
              size="default"
              variant="outlined"
            >
              Edit<span className="sr-only">, </span>
            </button>
          </Link>

          <span className="inline-flex px-2 ml-3 text-xs leading-5 text-red-600 bg-red-100 rounded-full">
            <button
              onClick={() => {
                onDeleteOpen(cellId);
              }}
              size="default"
              variant="outlined"
            >
              Delete
              <span className="sr-only">, </span>
            </button>
          </span>
        </>
      );
    },
  },
];

export const product_columns = ({
  issueModalOpen,
  onDeleteOpen,
  handleDrawer,
  imageBaseURL,
  onStatusUpdate,
  allowedActions,
  openHandlerModal,
  handleSaveProductPosition,
  onOpenCreatedByModal,
  onRemoveProduct,
  handleCheckboxChange,
  selectedProductIds,
  isSelectAll,
  handleSelectAllChange,
}) => [
    {
      // Header: (
      // <input
      //   type="checkbox"
      //   checked={isSelectAll}
      //   onChange={handleSelectAllChange}
      // />
      // ),
      Header: "CheckBox",
      accessor: "checkbox",
      Cell: ({ row }) => (
        <input
          type="checkbox"
          // checked={selectedProductIds.includes(row.original.prod_id)}
          checked={selectedProductIds.some((p) => p.prod_id === row.original.prod_id)}
          onChange={() => handleCheckboxChange(row.original)}
        />
      ),
    },
    {
      Header: "Id",
      accessor: "prod_id",
      Cell: (cell) => {
        const cellid = cell?.row?.original?.prod_id;

        return <span className="sm:text-sm text-gray-500">{cellid}</span>;
      },


    },

    {
      Header: "Position",
      accessor: "product_position",
      Cell: (cell) => {
        let result_value = cell.row.original.product_position;
        let ser_id = cell.row.original.ser_id;
        let prod_id = cell.row.original.prod_id;
        let originalCell = cell.row.original;
        const [editing, setEditing] = useState(false);
        const [newPositionValue, setNewPositionValue] = useState(result_value);

        const toggleEditing = () => {
          setEditing(!editing);
        };
        const handleInputChange = (event) => {
          setNewPositionValue(event.target.value);
        };
        const handleSaveUnit2 = () => {
          function errorCallBack() {
            setNewPositionValue(0);
          }

          handleSaveProductPosition(
            originalCell,
            newPositionValue,
            ser_id,
            prod_id,
            errorCallBack
          ); //Uncomment After Call This Function

          setNewPositionValue(newPositionValue); // Replace savedValue with the actual saved value
          cell.row.original.product_position = newPositionValue;

          setEditing(false);
        };

        return (
          <p className="text-sm text-gray-500">
            {editing ? (
              <div className="pr-10">
                <input
                  type="text"
                  value={
                    newPositionValue == 0 || !newPositionValue
                      ? ""
                      : newPositionValue
                  }
                  onChange={handleInputChange}
                  className="w-full rounded-md"
                />
                <span className="inline-flex px-2 ml-3 text-xs leading-5 rounded-full text-cyan-600 bg-cyan-100">
                  <button
                    size="default"
                    variant="outlined"
                    onClick={() => {
                      handleSaveUnit2();
                    }}
                  >
                    Save
                  </button>
                </span>
              </div>
            ) : (
              <div>
                <button
                  className="text-center rounded-md break-words w-full px-1 py-2 text-sm font-medium text-cyan-600 bg-cyan-200 hover:bg-cyan-300 focus:outline-none focus:bg-cyan-300 transition duration-150 ease-in-out"
                  size="default"
                  variant="outlined"
                  onClick={toggleEditing}
                >
                  {newPositionValue ? newPositionValue : "Empty"}
                </button>
              </div>
            )}
          </p>
        );
      },
    },

    {
      Header: "Product Name",
      accessor: "ser_prod_name",
    },


    {
      Header: "Launch Year",
      accessor: "ser_prod_launch_date",
    },
    {
      Header: "Published At",
      accessor: d => {
        const date = d?.ser_prod_publishedAt;
        if (date) {
          const formattedDate = moment(date).format('DD/MM/YYYY hh:mm:ss A');
          return formattedDate;
        } else {
          return ''; // Show empty string if date is invalid or not available
        }
      },
    },

    {
      Header: "Categories",
      accessor: "ser_prod_categories_json.data",
      Cell: ({ value }) => {
        if (!value || value.length === 0) {
          return <span className="text-sm text-gray-500">No Categories</span>;
        }
        const categoryNames = value.map((cat) => cat.attributes.name).join(", ");

        return <span className="text-sm text-gray-500">{categoryNames}</span>;
      },
    },
    // {
    //   Header: "Type",
    //   accessor: "prod_is_normal",
    //   Cell: (cell) => {
    //     const prod_is_normal = cell?.row?.original?.prod_is_normal;
    //     const prod_is_sponsored = cell?.row?.original?.prod_is_sponsored;

    //     return (
    //       <span className="sm:text-sm text-gray-500">
    //         {prod_is_sponsored ? "Sponsored" : prod_is_normal ? "Normal" : ""}
    //       </span>
    //     );
    //   },
    //   //accessor: (d) => capitalise(d.mm_name),
    // },

    // {
    //   Header: "Updated At",
    //   accessor: "ser_prod_updatedAt",
    //   Cell: (cell) => {
    //     const cellId = cell.row.original.ser_prod_updatedAt;
    //     return (
    //       <span className="sm:text-sm text-gray-500">
    //         {new Date(cellId).toLocaleString("en-US", {
    //           timeZone: "Asia/Kolkata",
    //         })}
    //       </span>
    //     );
    //   },
    // },
    {
      Header: "Updated By / Created By",

      accessor: (row) => row,
      Cell: ({ value }) => {
        const updatedBy = value?.product_uu_relation?.user_first_name ? value?.product_uu_relation?.user_first_name : "" + " " + (value?.product_uu_relation?.user_last_name ? value?.product_uu_relation?.user_last_name : "");
        const createdBy = value?.product_cu_relation?.user_first_name ? value?.product_cu_relation?.user_first_name : "" + " " + (value?.product_uu_relation?.user_last_name ? value?.product_uu_relation?.user_last_name : "");

        return (
          <div className="sm:text-sm text-gray-500">
            <div>Updated By: {updatedBy}</div>
            <div>
              Created By: {createdBy}
              <Link className="inline-flex px-2 ml-3 text-xs leading-5 rounded-full text-cyan-600 bg-cyan-100">
                <button
                  className="text-cyan-900 "
                  size="default"
                  variant="outlined"
                  onClick={() => onOpenCreatedByModal(value)}
                >
                  View<span className="sr-only">, </span>
                </button>
              </Link>
            </div>
          </div>
        );
      },
    },
    {
      Header: "Updated At / Created At",

      accessor: (row) => row,
      Cell: ({ value }) => {
        const updatedAt = value.ser_prod_updatedAt;
        const createdAt = value.ser_prod_createdAt;
        const formatDate = (dateString) => {
          if (!dateString) {
            return "----";
          }
          return new Date(dateString).toLocaleDateString("en-US", {
            timeZone: "Asia/Kolkata",
          });
        };

        return (
          <div className="sm:text-sm text-gray-500">
            <div>Updated At: {formatDate(updatedAt)}</div>
            <div>Created At: {formatDate(createdAt)}</div>
          </div>
        );
      },
    },

    // {
    //   Header: "IsApproved",
    //   accessor: "approved",
    //   Cell: (cell) => {
    //     const cellId = cell?.row?.original?.prod_is_approved;

    //     return (
    //       <span className="sm:text-sm text-gray-500">
    //         {cellId ? "Approved" : "Unapproved"}
    //       </span>
    //     );
    //   },
    // },
    {
      Header: "Status",
      accessor: "publishedAt",
      Cell: (cell) => {
        const publishedAt = cell.row.original.ser_prod_publishedAt;

        return (
          <>
            <span className="text-gray-500 sm:text-sm">
              {publishedAt ? "Published" : "Draft"}
            </span>
          </>
        );
      },
    },
    // {
    //   Header: "User Claim",
    //   accessor: "product_user_claim_map",
    //   Cell: (cell) => {
    //     const userclaim = cell?.row?.original?.product_user_claim_map;
    //     const cellmodal = cell?.row?.original;
    //     const isApproved = cell?.row?.original?.prod_is_approved
    //     console.log("isApproved", isApproved, "cellmodal", cellmodal)
    //     return (
    //       <button

    //         onClick={isApproved || userclaim?.length === 0 ? null : () => openHandlerModal(cellmodal)}
    //         className={!isApproved && userclaim?.length > 0 ? "sm:text-sm  bg-green-200 text-green-600 inline-flex px-5 ml-3 rounded-lg  leading-5 shadow-lg" :
    //           isApproved ? " sm:text-sm bg-gray-200 text-gray-600  inline-flex px-5 ml-3 rounded-lg  leading-5 shadow-lg" : `sm:text-sm shadow-lg bg-red-200 text-red-600 inline-flex px-5 ml-3 rounded-lg  leading-5  cursor-pointer`}
    //       >
    //         {userclaim?.length}
    //       </button>
    //     );
    //   },
    // },
    {
      Header: "Action",
      accessor: "action",
      Cell: (cell) => {
        const cellId = cell.row.original.product_id;
        const navigate = useNavigate();

        return (
          <>
            {allowedActions.filter((data) => data.permission_id == 24)?.length >
              0 ? (
              <Link
                // onClick={() => {
                //   navigate(`/editproduct/${cell.row.original.product_id}`)
                // }}
                to={`/editproduct/${cell.row.original.ser_id}`}
                className="inline-flex px-2 ml-3 text-xs leading-5 rounded-full text-cyan-600 bg-cyan-100"
              >
                <button
                  className="text-green-900 "
                  size="default"
                  variant="outlined"
                >
                  Edit
                </button>
              </Link>
            ) : null}

            {allowedActions.filter((data) => data.permission_id == 26)?.length >
              0 ? (
              <span
                onClick={() => onDeleteOpen(cell.row.original, cell.row.index)}
                className={`"inline-flex px-2 ml-3 text-xs leading-5 rounded-full  ${cell.row.original.ser_prod_publishedAt
                  ? "bg-yellow-200 text-yellow-600"
                  : "bg-red-200 text-red-600"
                  }"`}
              >
                {cell.row.original.ser_prod_publishedAt ? (
                  <button
                    className="text-yellow-900  "
                    size="default"
                    variant="outlined"
                  >
                    Inactive
                  </button>
                ) : (
                  <button
                    className="text-red-900 "
                    size="default"
                    variant="outlined"
                  >
                    Active
                  </button>
                )}
              </span>
            ) : null}

            {allowedActions.filter((data) => data.permission_id == 25)?.length >
              0 ? (
              <span
                onClick={() => onRemoveProduct(cell.row.original.ser_id, cell.row.original, cell.row.index)}
                className={`"inline-flex px-2 ml-3 text-xs leading-5 rounded-full  ${cell.row.original.ser_id
                  ? "bg-red-200 text-red-600"
                  : "bg-red-200 text-red-600"
                  }"`}
              >
                <button
                  className="text-yellow-900  "
                  size="default"
                  variant="outlined"
                >
                  Remove
                </button>

              </span>) : null}

          </>
        );
      },
    },
  ];

export const prod_Modal_columns = ({ ApprovedHandler, ovalSpinner }) => [
  {
    Header: "Username",
    accessor: "m_user_pucm_relation.user_username",
    //accessor: (d) => capitalise(d.mm_name),
  },
  {
    Header: "Product Name",
    accessor: "m_products_relation.ser_prod_name",
  },
  {
    Header: "Status",
    accessor: "publishedAt",
    Cell: (cell) => {
      const publishedAt =
        cell?.row?.original?.m_products_relation?.ser_prod_publishedAt;
      console.log("datamodal", cell?.row?.original);
      return (
        <>
          <span className="text-gray-500 sm:text-sm ">
            {publishedAt ? "Published" : "Draft"}
          </span>
        </>
      );
    },
  },
  {
    Header: "IsApproved",
    accessor: "approved",
    Cell: (cell) => {
      const cellId = cell?.row?.original?.pucm_is_approved;

      return (
        <span className="sm:text-sm text-gray-500">
          {cellId ? "Approved" : "Unapproved"}
        </span>
      );
    },
  },
  {
    Header: "Action",
    accessor: "action",
    Cell: (cell) => {
      const cellId = cell.row.original;
      return (
        <span
          className={`"inline-flex px-5 py-2  text-xs leading-5 rounded-full  ${!cell.row.original.pucm_is_approved
            ? "bg-red-200 text-red-600"
            : null
            }"`}
        >
          {!cell.row.original.pucm_is_approved ? (
            <button
              className="text-yellow-900  text-center "
              size="default"
              variant="outlined"
              onClick={() => ApprovedHandler(cellId)}
            >
              {ovalSpinner ? <OvalLoader className="" /> : "Approved"}
            </button>
          ) : null}
        </span>
      );
    },
  },
];
export const order_columns = ({ onDeleteOpen }) => [
  {
    Header: "Order Id",
    accessor: (d) => capitalise(d.order_id),
  },
  {
    Header: "Order Date",
    accessor: (d) => capitalise(d.order_date),
  },
  {
    Header: "Action",
    accessor: "action",
    Cell: (cell) => {
      const cellId = cell.row.original.order_id;
      return (
        <>
          <Link
            // onClick={() => handleDrawer("edit", cellId, cell.row.original)}
            to={`../add-order/${cellId}`}
            className="inline-flex px-2 ml-3 text-xs leading-5 rounded-full text-cyan-600 bg-cyan-100"
          >
            <button
              className="text-cyan-900 "
              size="default"
              variant="outlined"
            >
              Edit<span className="sr-only">, </span>
            </button>
          </Link>

          <span className="inline-flex px-2 ml-3 text-xs leading-5 text-red-600 bg-red-100 rounded-full">
            <button
              onClick={() => {
                onDeleteOpen(cellId);
              }}
              size="default"
              variant="outlined"
            >
              Inactive
              <span className="sr-only">, </span>
            </button>
          </span>
        </>
      );
    },
  },
];

export const enquiry_columns = ({ onModalOpen }) => [
  {
    Header: "Name",
    accessor: (d) => {
      let firstName = d?.m_user_enq_relation?.user_first_name || ""; // Using optional chaining and default value
      const lastName = d?.m_user_enq_relation?.user_last_name || ""; // Using optional chaining and default value
      if (!firstName || firstName == '') {
        firstName = d.enq_name
      }
      return capitalise(firstName) + " " + capitalise(lastName);
    },
  },

  {
    Header: "Email",
    accessor: "enq_business_email",
  },
  {
    Header: "Product",
    accessor: "m_products_relation.ser_prod_name",
  },
  // {
  //   Header: "Source",
  //   accessor: "enq_type",
  // },

  {
    Header: "Source",

    accessor: (d) => capitalise(d?.enq_type),
  },




  ,
  {
    Header: "Date",
    accessor: (d) => moment(d?.enq_created_at).format("DD/MM/YYYY"),
  },
  {
    Header: "Action",
    accessor: "action",
    Cell: (cell) => {
      const cellId = cell;
      return (
        <Link
          // onClick={() => handleDrawer("edit", cellId, cell.row.original)}
          // to={`../add-order/${cellId}`}
          className="inline-flex px-2 ml-3 text-xs leading-5 rounded-full text-cyan-600 bg-cyan-100"
        >
          <button
            className="text-cyan-900 "
            onClick={() => onModalOpen(cell.row.original)}
            size="default"
            variant="outlined"
          >
            View<span className="sr-only">, </span>
          </button>
        </Link>
      );
    },
  },
];

export const followup_columns = ({ onModalOpen }) => [
  {
    Header: "Product",
    accessor: "ser_prod_name",
  },
  {
    Header: "Total Followers",
    accessor: "total_follower",
    Cell: (cell) => {
      const cellData = cell.row.original.m_following;
      console.log("cell", cellData)
      return (
        <><p>{cellData?.length}</p></>
      )
    }
  },
  {
    Header: "Action",
    accessor: "action",
    Cell: (cell) => {
      const cellData = cell.row.original.m_following;
      // const otherData = cell.row.original
      // const updateData = cell.row.original.m_following.map((ele)=>({
      //   ...ele,
      //   otherData : otherData
      // }))
      // console.log("cellData",updateData)
      return (
        <>
          <Link
            className="inline-flex px-2 ml-3 text-xs leading-5 rounded-full text-cyan-600 bg-cyan-100"
          >
            <button
              className="text-cyan-900 "
              size="default"
              variant="outlined"
              onClick={() => {
                onModalOpen(cellData);
              }}
            >
              View<span className="sr-only">, </span>
            </button>
          </Link>
        </>
      )
    }
  }
];

export const followerUser_column = () => [

  {
    Header: "UserName",
    accessor: (item) => { return `${item?.m_user_follow_relation?.user_first_name} ${item?.m_user_follow_relation?.user_last_name}` },
  },
  {
    Header: "email",
    accessor: (item) => { return `${item?.m_user_follow_relation?.user_email}` }
  },
  {
    Header: "company",
    accessor: (item) => { return `${item?.m_user_follow_relation?.user_company_name}` }
  },
  {
    Header: "Designation",
    accessor: (item) => { return `${item?.m_user_follow_relation?.user_designation}` }
  },
  {
    Header: "Role",
    accessor: (item) => { return `${item?.follow_cu_relation?.m_role_relation?.role_name}` }
  },
  {
    Header: "Mobile",
    accessor: (item) => { return `${item?.m_user_follow_relation?.user_mobile}` }
  },
  {
    Header: "follow_Date",
    accessor: (item) => { return moment(item?.follow_created_at).format("DD/MM/YYYY") }

  },

]

export const claims_columns = ({
  onModalOpen,
  onApproveModalOpen, onStatusUpdate,
  userList,
  handleSaveUserDrop,
}) => [
    // {
    //   Header: "User Name",
    //   accessor: "m_user_pucm_relation.user_username",
    // },

    {
      Header: "Name",
      accessor: (d) => {

        const firstName = d?.m_user_pucm_relation?.user_first_name || ""; // Using optional chaining and default value
        const lastName = d?.m_user_pucm_relation?.user_last_name || ""; // Using optional chaining and default value
        return capitalise(firstName) + " " + capitalise(lastName);
      },


    },



    {
      Header: "Change Transfer claim",
      accessor: "fk_user_id",
      Cell: (cell) => {
        console.log("cellll", cell)
        let result_value = cell.row.original?.m_user_pucm_relation?.user_username;
        let result_value_email =
          cell.row.original?.m_user_pucm_relation?.user_email;
        // console.log(result_value_email);
        let pucm_id = cell.row.original.pucm_id;
        let user_id = cell.row.original.fk_user_id;
        let pucm_user_name = cell.row.original.pucm_user_name;
        const pucm_is_claimed = cell.row.original.pucm_is_approved;
        const pucm_is_approved = cell.row.original.pucm_is_approved;
        let originalCell = cell.row.original;
        const [selectedUserId, setSelectedUserId] = useState({
          value: user_id,
          label: `${result_value} (${cell.row.original?.m_user_pucm_relation?.user_email})`, // Adjust initial state if necessary to include email
        });
        const [editing, setEditing] = useState(false);
        const [newUserValue, setNewUserValue] = useState(result_value);

        const toggleEditing = () => {
          console.log("PS=>", cell?.row?.index)

          setEditing(!editing);
        };
        const handleInputChange = (event) => {
          setNewUserValue(event.target.value);
        };
        const handleSaveUser = () => {
          handleSaveUserDrop(originalCell, newUserValue, selectedUserId, pucm_id); //Uncomment After Call This Function

          setNewUserValue(newUserValue); // Replace savedValue with the actual saved value
          cell.row.original.m_user_pucm_relation.user_username = newUserValue;
          setEditing(false);
        };

        const userOptions = userList.map((user) => ({
          value: user.user_id,
          label: `${user.user_first_name} (${user.user_email})`,
        }));
        const hrSourceProviders = userList.filter(user => user?.user_is_hr_source_provider);
        const userOptionshrSourceProviders = hrSourceProviders.map((user) => ({
          value: user.user_id,
          label: `${user.user_first_name} (${user.user_email})`,
        }));
        // userList: hrSourceProviders,
        const isLastRow = (index) => {
          // Determine if the current row is the last row in the table
          // return index === cell?.data?.length - 1; // Assuming tableData is an array containing table rows
          if (index === cell?.data?.length - 2) {
            return index === cell?.data?.length - 2;
          }
          if (index === cell?.data?.length - 1) {
            return index === cell?.data?.length - 1;
          }
        };
        console.log("userOptions", userOptions)
        return (
          <p className="text-sm text-gray-500 flex items-center">
            {(editing && pucm_is_claimed == true) ? (
              <div className="flex items-center z-50">
                {(pucm_is_claimed == true && userList) ? (
                  <Select
                    name="user_username"
                    id="user_id"
                    placeholder={<span>Select User</span>}
                    value={selectedUserId}
                    onChange={(e) => {
                      setSelectedUserId(e);
                    }}
                    components={animatedComponents}
                    options={userList ? userOptionshrSourceProviders : []}
                    menuPlacement={isLastRow(cell?.row?.index) ? 'top' : 'bottom'}
                    styles={{
                      menuPortal: (base) => ({
                        ...base,
                        zIndex: 1000,

                      }),
                    }}
                    menuPortalTarget={document.body}
                    classNamePrefix="select"
                    className="basic-multi-select block w-80 max-w-lg shadow-sm focus:border-[1px] focus:border-cyan-500 sm:max-w-xs sm:text-sm"
                  />
                ) : (
                  <></>
                )}

                <span className="inline-flex px-2 ml-3 text-xs leading-5 rounded-full text-cyan-600 bg-cyan-100">
                  <button
                    size="default"
                    variant="outlined"
                    onClick={() => {
                      handleSaveUser();
                    }}
                  >
                    Save
                  </button>
                </span>
              </div>
            ) : (
              <></>

            )}
            {pucm_is_claimed == true ? (
              <div>
                <button
                  className="text-center rounded-md break-words w-sm px-1 py-2 text-sm font-medium text-cyan-600 bg-cyan-200 hover:bg-cyan-300 focus:outline-none focus:bg-cyan-300 transition duration-150 ease-in-out"
                  size="default"
                  variant="outlined"
                  onClick={toggleEditing}
                >
                  {newUserValue ? newUserValue : pucm_user_name}
                </button>
              </div>
            ) : (<></>)}
          </p>
        );
      },
    },
    {
      Header: "Product",
      accessor: "m_products_relation.ser_prod_name",
    },

    {
      Header: "Status",
      accessor: "status",

      Cell: (cell) => {
        const pucm_is_claimed = cell.row.original.pucm_is_approved;
        const pucm_is_approved = cell.row.original.pucm_is_approved;

        return (
          <span className="sm:text-sm text-gray-500">
            {pucm_is_claimed
              ? pucm_is_approved
                ? " Approved"
                : "Claimed"
              : "Unclaimed"}
          </span>
        );
      },
    },
    {
      Header: "Action",
      accessor: "action",
      Cell: (cell) => {
        const cellId = cell;
        const pucm_is_claimed = cell.row.original.pucm_is_approved;
        const pucm_is_approved = cell.row.original.pucm_is_approved;
        return (
          <div className="flex gap-x-2">
            <Link
              // onClick={() => handleDrawer("edit", cellId, cell.row.original)}
              // to={`../add-order/${cellId}`}
              className="inline-flex px-2 ml-3 text-xs leading-5 rounded-full text-cyan-600 bg-cyan-100"
            >
              <button
                className="text-cyan-900 "
                size="default"
                variant="outlined"
                onClick={() => {
                  onModalOpen(cell.row.original);
                }}
              >
                View<span className="sr-only">, </span>
              </button>
            </Link>
            {pucm_is_claimed ? (
              <span className="inline-flex px-2 ml-3 text-xs leading-5 rounded-full text-cyan-600 bg-cyan-100">
                <button size="default" variant="outlined" onClick={() => { onStatusUpdate(cell.row.original); }}>Revoke</button>
              </span>) : (<></>)}
            {/* {cell.row.original?.pucm_is_approved ? (
              <Link
               
                className="inline-flex px-2 ml-3 text-xs leading-5 rounded-full text-cyan-600 bg-green-100"
              >
                <button
                  disabled
                  className="text-green-900 "
                  size="default"
                  variant="outlined"
                  onClick={() => {
                    onApproveModalOpen(cell.row.original);
                  }}
                >
                  <span className="text-green-900">Approved</span>
                  <span className="sr-only">, </span>
                </button>
              </Link>
            ) : (
              <Link
                
                className="inline-flex px-2 ml-3 text-xs leading-5 rounded-full text-cyan-600 bg-red-100"
              >
                <button
                  className="text-red-900 "
                  size="default"
                  variant="outlined"
                  onClick={() => {
                    onApproveModalOpen(cell.row.original);
                  }}
                >
                  <span className="text-red-900">Approve</span>
                  <span className="sr-only">, </span>
                </button>
              </Link>
            )} */}
          </div>
        );
      },
    },
  ];

export const claims_view_columns = ({
  onModalOpen,
  approveClaim,
  onUserModalOpen,
}) => [
    // {
    //   Header: "User Name",
    //   accessor: "m_user_pucm_relation.user_username",
    // },
    {
      Header: "User Name",
      accessor: "m_user_pucm_relation.user_username",
      Cell: (cell) => {
        const userData = cell.row.original;
        console.log(userData);
        console.log("setIsApproved", cell.row.original)
        return (
          <div className="flex gap-x-2 items-center">
            <span
              className="text-gray-900 cursor-pointer"
              onClick={() => {
                onUserModalOpen(userData);
              }}
            >
              {cell.value}
            </span>
            <button
              onClick={() => {
                onUserModalOpen(userData);
              }}
              className="text-blue-500 text-sm"
            >
              View
            </button>
          </div>
        );
      },
    },
    {
      Header: "Product",
      accessor: "m_products_relation.ser_prod_name",
    },

    {
      Header: "Status",
      accessor: "status",

      Cell: (cell) => {
        const pucm_is_claimed = cell.row.original.pucm_is_approved;
        const pucm_is_approved = cell.row.original.pucm_is_approved;

        return (
          <span className="sm:text-sm text-gray-500">
            {pucm_is_claimed
              ? pucm_is_approved
                ? " Approved"
                : "Claimed"
              : "Unclaimed"}
          </span>
        );
      },
    },
    {
      Header: "Action",
      accessor: "action",
      Cell: (cell) => {
        const cellId = cell;

        const [isApproved, setIsApproved] = useState(
          cell.row.original?.pucm_is_approved
        );
        const [loader, setLoader] = useState(false);
        return (
          <div className="flex gap-x-2">
            {loader && (
              <div className="inline-flex px-2 ml-3">
                <OvalLoader />
              </div>
            )}
            {isApproved ? (
              <Link
                // onClick={() => handleDrawer("edit", cellId, cell.row.original)}
                // to={`../add-order/${cellId}`}
                className="inline-flex px-2 ml-3 text-xs leading-5 rounded-full text-cyan-600 bg-green-100"
              >
                <button
                  disabled
                  className="text-green-900 "
                  size="default"
                  variant="outlined"
                  onClick={() => {
                    // approveClaim(cell.row.original, setIsApproved)
                  }}
                >
                  <span className="text-green-900">Approved</span>
                  <span className="sr-only">, </span>
                </button>
              </Link>
            ) : (
              !loader && (
                <Link
                  // onClick={() => handleDrawer("edit", cellId, cell.row.original)}
                  // to={`../add-order/${cellId}`}
                  className="inline-flex px-2 ml-3 text-xs leading-5 rounded-full text-cyan-600 bg-red-100"
                >
                  <button
                    disabled={
                      loader || cell.row.original.prod_is_approved ? true : false
                    }
                    className="text-red-900 "
                    size="default"
                    variant="outlined"
                    onClick={() => {
                      approveClaim(cell.row.original, setIsApproved, setLoader);
                    }}
                  >
                    <span className="text-red-900">Approve</span>
                    <span className="sr-only">, </span>
                  </button>
                </Link>
              )
            )}
          </div>
        );
      },
    },
  ];

export const claims_products_columns = ({
  onModalOpen,
  onApproveModalOpen,
}) => [
    {
      Header: "Product Name",
      accessor: "ser_prod_name",
    },

    {
      Header: "Launch Datajay",
      // accessor: "ser_prod_launch_date",
      accessor: (d) => moment(d?.prod_created_at).format("DD/MM/YYYY")

    },

    {
      Header: "Action",
      accessor: "action",
      Cell: (cell) => {
        const cellId = cell;
        console.log("Action", cell.row)
        let data = cell.row.original?.product_user_claim_map || [];
        // data = data.map((v) => ({
        //   ...v,
        //   prod_is_approved: cell.row.original.prod_is_approved,
        // }));
        data = data.filter(v => v?.pucm_is_claimed === true && v?.pucm_is_approved === false)
        // console.log("jay",data,"cell",cell.row.original);

        return (
          <div className="flex gap-x-2">
            <Link
              // onClick={() => handleDrawer("edit", cellId, cell.row.original)}
              // to={`../add-order/${cellId}`}
              className="inline-flex px-2 ml-3 text-xs leading-5 rounded-full text-cyan-600 bg-cyan-100"
            >
              <button
                className="text-cyan-900 "
                size="default"
                variant="outlined"
                onClick={() => {
                  onModalOpen(data);
                }}
              >
                View<span className="sr-only">, </span>
              </button>
            </Link>
            <Link
              // onClick={() => handleDrawer("edit", cellId, cell.row.original)}
              // to={`../add-order/${cellId}`}
              className="inline-flex px-2 ml-3 text-xs leading-5 rounded-full text-cyan-600 bg-cyan-100"
            >
              <button
                className="text-cyan-900 "
                size="default"
                variant="outlined"
                onClick={() => {
                  onModalOpen(data);
                }}
              >
                {Array.isArray(data) && data.length}
                <span className="sr-only">, </span>
              </button>
            </Link>
          </div>
        );
      },
    },
  ];

export const unclaims_products_columns = ({
  onModalOpen,
  onApproveModalOpen,
}) => [
    {
      Header: "Product Name",
      accessor: "ser_prod_name",
    },

    {
      Header: "Launch Data",
      accessor: "ser_prod_launch_date",
    },

    {
      Header: "Action",
      accessor: "action",
      Cell: (cell) => {
        const cellId = cell;
        return (
          <div className="flex gap-x-2">
            <Link
              // onClick={() => handleDrawer("edit", cellId, cell.row.original)}
              // to={`../add-order/${cellId}`}
              className="inline-flex px-2 ml-3 text-xs leading-5 rounded-full text-cyan-600 bg-cyan-100"
            >
              <button
                className="text-cyan-900 "
                size="default"
                variant="outlined"
                onClick={() => {
                  onModalOpen(cell.row.original);
                }}
              >
                View<span className="sr-only">, </span>
              </button>
            </Link>
          </div>
        );
      },
    },
  ];

export const review_columns = ({
  onModalOpen,
  onApproveModalOpen,
  openModal,
}) => [
    {
      Header: "Name",
      accessor: (d) =>
        capitalise(d.m_user_rr_relation.user_first_name) +
        " " +
        capitalise(d.m_user_rr_relation.user_last_name),
    },

    {
      Header: "Email",
      accessor: "user_email",

      Cell: (cell) => {
        console.log(cell);
        const email = cell?.row?.original?.m_user_rr_relation?.user_email;

        return <span className="sm:text-sm text-gray-500">{email}</span>;
      },
    },

    {
      Header: "Product",
      // accessor: "ser_product_name",
      accessor: (d) => capitalise(d.m_products_relation.ser_prod_name),
      Cell: (cell) => {
        const product = cell?.row?.original?.m_products_relation?.ser_prod_name;

        return <span className="sm:text-sm text-gray-500">{product}</span>;
      },
    },
    {
      Header: "Review",
      accessor: "rr_star_rating",
      Cell: (cell) => {
        const rating = cell?.row?.original?.rr_star_rating;

        const renderStars = (rating) => {
          const stars = [];
          for (let i = 1; i <= 5; i++) {
            if (i <= rating) {
              stars.push(
                <span key={i} className="text-yellow-500">
                  &#9733;
                </span>
              ); // Unicode star character
            } else {
              stars.push(
                <span key={i} className="text-gray-500">
                  &#9734;
                </span>
              ); // Unicode empty star character
            }
          }
          return stars;
        };

        return (
          <span className="sm:text-sm text-gray-500">{renderStars(rating)}</span>
        );
      },
    },



    {
      Header: "Date",
      // accessor: d => moment(d?.rr_created_at).format('DD/MM/YYYY hh:mm:ss A'),
      accessor: d => moment(d?.rr_created_at).format('DD/MM/YYYY'),
    },



    {
      Header: "Action",
      accessor: "action",
      Cell: (cell) => {
        const user_first_name =
          cell?.row?.original?.m_user_rr_relation?.user_first_name;
        const user_email = cell?.row?.original?.m_user_rr_relation?.user_email;
        const rr_created_at = cell?.row?.original?.rr_created_at;
        const rr_remark = cell?.row?.original?.rr_remark;
        const rr_star_rating = cell?.row?.original?.rr_star_rating;

        return (
          <div className="flex gap-x-2">
            <Link
              // onClick={() => handleDrawer("edit", cellId, cell.row.original)}
              // to={`../add-order/${cellId}`}
              className="inline-flex px-2 ml-3 text-xs leading-5 rounded-full text-cyan-600 bg-cyan-100"
            >
              <button
                className="text-cyan-900 "
                size="default"
                variant="outlined"
                onClick={() => openModal(cell?.row?.original)}
              >
                View<span className="sr-only">, </span>
              </button>
            </Link>
          </div>
        );
      },
    },
  ];
